import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetSubUploadedObjectMetadataResponseModel, GetUploadedObjectMetadataResponseModel, GetUploadedObjectResponseModel } from "../../../models/object/uploadedObject/get.uploadedObject.response.model";
import {GetUploadedObjectMetadataService } from "../../../services/uploadedObject/get.uploaded.object.metadata.service";


interface GetUploadedObjectState extends BaseState<GetSubUploadedObjectMetadataResponseModel>{

}


let initialState:GetUploadedObjectState={
    data:null,
    error:null,
    loading:false
}





export const getUploadedObjectMetadata=createAsyncThunk("uploadedObjectMetadata/get",async(urn:string)=>{

    const getUploadedObjectMetadataService=new GetUploadedObjectMetadataService("objects","getModelDetail")
    const response=await getUploadedObjectMetadataService.GetByStringParamAsync(urn)

    return response
})






export const getUploadedObjectMetadataSlice=createSlice({
    name:"getUploadedObjectMetadataSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {   
        builder
        .addCase(getUploadedObjectMetadata.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(getUploadedObjectMetadata.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(getUploadedObjectMetadata.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})

export default getUploadedObjectMetadataSlice.reducer;
