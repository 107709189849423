import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { fetchUserInfo } from '../../store/features/user/get.user.slice';
import { LoginResponse } from '../../models/auth/login.model';
import { Box, Card, CardContent, Typography, LinearProgress, Divider, Avatar } from '@mui/material';
import { Email, Phone, Business, CreditCard, Stars } from '@mui/icons-material';

export default function UserProfile() {
  const dispatch = useAppDispatch();
  const userInfoSlice = useAppSelector((state: RootState) => state.userInfoSlice);
  const userInfo = userInfoSlice.data;
  const login = sessionStorage.getItem('login') as string;
  const jsonLogin = JSON.parse(login) as LoginResponse;

  useEffect(() => {
    dispatch(fetchUserInfo(jsonLogin.uid));
  }, [dispatch, jsonLogin.uid]);

  if (!userInfo || userInfoSlice.error) {
    return (
      <Box className="min-h-screen flex items-center justify-center bg-gray-50">
        <Typography className="text-2xl font-bold text-gray-800">Loading user information...</Typography>
      </Box>
    );
  }

  const creditUsagePercentage = (userInfo.usedCredit / (userInfo.usedCredit + userInfo.availableCredit)) * 100;

  return (
    <Box className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <Card className="max-w-4xl mx-auto bg-white shadow-lg rounded-2xl overflow-hidden border border-gray-200">
        <CardContent className="p-8">
          <Box className="space-y-8">
            {/* User Header Section */}
            <Box className="flex items-center space-x-6">
              <Avatar alt={userInfo.userName} src="/avatar-placeholder.png" sx={{ width: 80, height: 80 }} />
              <Box>
                <Typography variant="h4" className="text-3xl font-bold text-gray-900">
                  {userInfo.userName}
                </Typography>
                <Typography variant="subtitle1" className="text-lg text-gray-600">
                  {userInfo.email}
                </Typography>
              </Box>
            </Box>

            <Divider className="my-6" />

            {/* Information Section */}
            <Box className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <InfoItem icon={<Phone className="text-gray-700" />} label="Phone" value={userInfo.phoneNumber} />
              <InfoItem icon={<Business className="text-gray-700" />} label="Company" value={userInfo.companyName} />
              <InfoItem icon={<Stars className="text-gray-700" />} label="Subscription" value={userInfo.subscriptionType} />
            </Box>

            <Divider className="my-6" />

            {/* Credit Usage Section */}
            <Box className="space-y-4">
              <Typography variant="h6" className="text-xl font-semibold text-gray-900">
                Credit Usage
              </Typography>
              <Box className="relative pt-1">
                <LinearProgress
                  variant="determinate"
                  value={creditUsagePercentage}
                  sx={{
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: 'rgba(156, 163, 175, 0.3)', // Tailwind's gray-300
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 5,
                      backgroundColor: '#4F46E5' // Tailwind's indigo-600
                    }
                  }}
                />
                <Box className="flex justify-between mt-2">
                  <Typography className="text-sm font-medium text-gray-600">
                    Used: {userInfo.usedCredit}
                  </Typography>
                  <Typography className="text-sm font-medium text-gray-600">
                    Available: {userInfo.availableCredit}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

function InfoItem({ icon, label, value }: { icon: React.ReactNode; label: string; value: string | number }) {
  return (
    <Box className="flex items-center space-x-4 bg-white rounded-lg p-5 border border-gray-300 shadow-sm">
      <Box className="bg-gray-200 rounded-full p-3">
        {icon}
      </Box>
      <Box>
        <Typography variant="body2" className="text-gray-500 font-medium">
          {label}
        </Typography>
        <Typography variant="body1" className="text-gray-800 font-semibold">
          {value}
        </Typography>
      </Box>
    </Box>
  );
}
