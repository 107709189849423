import React, { useEffect } from 'react';

const TawkTo = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = 'https://embed.tawk.to/6728a47c2480f5b4f5985418/1ibrc5292';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);

    // Temizleme işlevi
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default TawkTo;
