import React, { useState } from 'react';

export default function Features({setShowSupportedFormats,showSupportedFormats}:any) {

    const featuresData = [
        {
            title: "Translate",
            description: "Revolutionize your catalog experience by breaking free from traditional constraints. Ensure your catalogs stay ahead of the curve in today's digital age by seamlessly converting your models into a flawless web-viewable format. Easily adapt to innovations and elevate your brand's presence like never before!",
            icon: "🔄",
        },
        {
            title: "Cloud Integration",
            description: "VEEWER provides easy connections to Google Drive, OneDrive, Box, and AWS. Convert your models to web formats and store them securely in your chosen cloud service. With VEEWER's upload feature, you can skip external cloud storage and access your models anytime, anywhere, simplifying your workflow.",
            icon: "☁️",
        },
        {
            title: "Embedded Code",
            description: "With VEEWER, you can easily get customizable embedded code to integrate your models into any website. Enhance your online presence and engage your audience with stunning 3D visuals. Take control of your digital showcase with VEEWER today!",
            icon: "📎",
        },
        {
            title: "Viewer",
            description: "Explore the potential of VEEWER's viewer! Use powerful tools to fine-tune details and adjust perspectives, enhancing your models. Elevate your viewing experience with VEEWER.",
            icon: "👁️",
        },
    ];

    return (
        <section className='py-16 bg-gray-100'>
            <div className='max-w-screen-2xl mx-auto px-4'>
                <h2 className='text-3xl font-bold text-center mb-12'>How to Create Your Own 3D Catalog?</h2>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8'>
                    {featuresData.map((feature, index) => (
                        <div key={index} className='p-6 bg-white shadow-lg rounded-lg'>
                            <div className='text-3xl mb-4'>{feature.icon}</div>
                            <h3 className='text-lg font-semibold mb-2'>{feature.title}</h3>
                            <p className='text-gray-600 text-sm'>{feature.description}</p>
                            { index === 0 &&  <span onClick={()=>setShowSupportedFormats(!showSupportedFormats)} className='underline text-blue-600 hover:text-blue-400 cursor-pointer hover:no-underline'>Supported Files</span> }
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
