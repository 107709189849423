

import { CheckCreditModel } from "../../models/object/uploadedObject/check.credit.model";
import { BaseGetService } from "../base/base.get.service";



export class CheckCreditService extends BaseGetService<CheckCreditModel>{
    constructor(controllerName:string="objects",endpoint:string="checkCredit"){
        super(controllerName,endpoint)
    }
}