import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { CheckCreditModel, CheckCreditRequestModel } from "../../../models/object/uploadedObject/check.credit.model";
import { CheckCreditService } from "../../../services/uploadedObject/check.credit.status.service";




interface CheckCreditState extends BaseState<CheckCreditModel>{

}


let initialState:CheckCreditState={
    data:null,
    error:null,
    loading:false
}



export const checkCreditUploadedObject=createAsyncThunk("uploadedObject/checkCredit",async(request:CheckCreditRequestModel,thunkAPI)=>{
    try{
        const service=new CheckCreditService()
        const response=await service.GetByStringParamAsync(request.bucketName)
        return response
    }
    catch(err: any){
        if (!err.response) {
            throw err;
          }
          console.log(err)
          const errorData = err.response.data
          console.log(errorData)

          return thunkAPI.rejectWithValue({
            message:errorData.message
          });
        }
    })



export const checkCreditUploadedObjectSlice=createSlice({
    name:"checkManifestUploadedObjectSlice",
    initialState,
    reducers:{
        resetCreditError:(state)=>{
            state.error = null;
            state.data=null;
            state.loading=false;
        }
    },
    extraReducers(builder){
        builder
        .addCase( checkCreditUploadedObject.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(checkCreditUploadedObject.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(checkCreditUploadedObject.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
        
    }
})


export const { resetCreditError } = checkCreditUploadedObjectSlice.actions;
export default checkCreditUploadedObjectSlice.reducer











