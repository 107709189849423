import React, { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CardItem from '../../components/CardItem';
import AWSDragnDrop from '../../components/AWS/AWSDragnDrop';
import IAM from '../../assets/IAM.png';
import IPT from '../../assets/IPT.png';
import ZIP from '../../assets/zip.svg';
import RVT from '../../assets/RVT.jpg';
import DWG from '../../assets/DWG.jpg';
import FBX from '../../assets/FBX.png';
import DWFX from '../../assets/DWFX.png';
import IDW from '../../assets/IDW.png';
import '../../styles/onedrive/upload.css';
import { fetchAllAWSFiles } from '../../store/features/aws/get.all.aws.files.slice';
import { fetchAllAWSFolders } from '../../store/features/aws/get.all.aws.folders.slice';
import CircleLoading from '../../components/Loading/CircleLoading';
import ConfirmDeleteModal from '../../components/ConfirmModals/ConfirmDeleteModal';
import TextAreaModal from '../../components/Modals/TextAreaModal';
import MultipleAWSUploadModal from '../../components/MultipleUploadModal/MultipleAWSUploadModal';
import { SelectableZipFiles } from '../object/ObjectList';
import http, { baseUrl } from '../../config/axios.config';
import { OneDriveFileData } from '../oneDrive/FileList';
import { GetIAMFilesModelResponse } from '../../models/oneDrive/file/get.iam.files.model';
import { fetchAWSIAMFiles } from '../../store/features/aws/get.iam.files.slice';
import { AWSData } from './AWSUpload';

const CombinedAWSComponent: React.FC = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showTranslationModal, setShowTranslationModal] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [secondSelectedFiles, setSecondSelectedFiles] = useState<AWSData[]>([]);
    const [fileLimit, setFileLimit] = useState(false);
    const [progress, setProgress] = useState(0);
    const [dragNdrop, setDragNdrop] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemToDeleteFile, setItemToDeleteFile] = useState({ id: '', name: '' });
    const [itemToDeleteFolder, setItemToDeleteFolder] = useState('');
    const [htmlContent, setHtmlContent] = useState('');
    const [htmlAreaModal, setHtmlAreaModal] = useState(false);
    const [loading, setLoading] = useState({ loading: false, itemName: '' });
    const [generateLoading, setGenerateLoading] = useState({ loading: false, itemName: '' });
    const [zipFile, setZipFile] = useState<SelectableZipFiles | undefined>();
    const [zipOption, setOpenZipOption] = useState(false);
    const dispatch = useAppDispatch();
    const awsFiles = useAppSelector((state: RootState) => state.getAllAWSFilesSlice);
    const awsFolders = useAppSelector((state: RootState) => state.getAllAWSFoldersSlice);
    const bucket = sessionStorage.getItem('b') as string;
    const MAX_COUNT = 40;
    const UploadFilesSwal = withReactContent(Swal);
    const [iamFiles, setIamFiles] = useState<GetIAMFilesModelResponse | undefined>(undefined)
    const iamFilesSelector = useAppSelector((s: RootState) => s.getIAMFilesSlice)

    useEffect(() => {
        dispatch(fetchAllAWSFiles(bucket));
        dispatch(fetchAllAWSFolders(bucket));
    }, [dispatch, bucket]);

    const handleUploadFiles = (chosenFiles: File[]) => {
        const uploaded = [...selectedFiles];
        let limitedExceeded = false;

        chosenFiles.map((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                if (file.name.endsWith('.zip')) {
                    setOpenZipOption(true);
                }
                uploaded.push(file);
            }
        });

        if (uploaded.length > 40) {
            Swal.fire({
                title: 'Maximum file upload limit exceeded',
                icon: 'error',
            });
            limitedExceeded = true;
        }

        if (!limitedExceeded) setSelectedFiles(uploaded);
        setFileLimit(uploaded.length >= 40);
    };

    const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadFiles(chosenFiles);
    };

    const handleCloseModal = () => {
        setShowTranslationModal(false)
        setShowModal(false);
        setSelectedFiles([]);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const renderFileIcon = (fileExtension: string) => {
        switch (fileExtension) {
            case 'zip':
                return ZIP;
            case 'iam':
                return IAM;
            case 'ipt':
                return IPT;
            case 'rvt':
                return RVT;
            case 'dwg':
                return DWG;
            case 'fbx':
                return FBX;
            case 'dwfx':
                return DWFX;
            case 'idw':
                return IDW;
            default:
                return "";
        }
    };

    const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const items = event.dataTransfer.items;
        let containsDirectory = false;

        for (let i = 0; i < items.length; i++) {
            const item = items[i].webkitGetAsEntry();
            if (item && item.isDirectory) {
                containsDirectory = true;
                break;
            }
        }

        if (containsDirectory) {
            alert('You cannot drag folders. Please drag only files.');
        } else {
            const files = Array.prototype.slice.call(event.dataTransfer.files);
            handleUploadFiles(files);
        }
    };

    const handleEmbedCodeAndOpen = async (folderName: string) => {

        const request = http('aws', `files/view/add/${encodeURIComponent(folderName)}`);
        setLoading({ loading: true, itemName: folderName });
        await request.get('').then(() => {
            setLoading({ loading: false, itemName: '' });

            window.open(`${baseUrl}aws/files/view/get/${bucket}/index.html`);
        });
    };

    const handleGenerateEmbedCode = async (folderName: string) => {
        const request = http('aws', `files/view/add/${encodeURIComponent(folderName)}`);
        setGenerateLoading({ loading: true, itemName: folderName });
        await request.get('').then(() => {
            folderName = folderName.replace('%20', ' ')
            const viewUrl = `${baseUrl}aws/files/view/get/${bucket}/index.html`;
            setGenerateLoading({ loading: false, itemName: '' });
            setHtmlContent(
                `<iframe frameborder='0' width='500px' height='500px' allowfullscreen mozallowfullscreen='true' webkitallowfullscreen='true' 
        fullscreen; xr-spatial-tracking xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share
        src='${viewUrl}'></iframe>`
            );
            setHtmlAreaModal(true);
        });
    };

    const handleTranslateFile = (file: OneDriveFileData) => {
        setSecondSelectedFiles([file]);
        setShowTranslationModal(true);
    };

    return (
        <>
            {
                awsFiles.loading && awsFolders.loading ? (
                    <CircleLoading text={'Trying to open models...'} />
                ) : (
                      <>
                        {dragNdrop && (
                            <AWSDragnDrop
                                setIsDragDrop={setDragNdrop}
                                handleFile={handleFileEvent}
                                handleCloseModal={handleCloseModal}
                                handleFileDrop={handleFileDrop}
                                selectedFiles={selectedFiles}
                                setSelectedFiles={setSelectedFiles}
                                bucket={bucket}
                            />
                        )}

                        {(
                            <>
                                <div className='w-full px-12 py-2 bg-white border-b-2 border-gray-400'>
                                    <button onClick={() => setDragNdrop((p) => !p)} className='onedrive-upload-btn'>
                                        Upload
                                    </button>
                                </div>
                                {dragNdrop && (
                                    <AWSDragnDrop
                                        setIsDragDrop={setDragNdrop}
                                        handleFile={handleFileEvent}
                                        handleCloseModal={handleCloseModal}
                                        handleFileDrop={handleFileDrop}
                                        selectedFiles={selectedFiles}
                                        setSelectedFiles={setSelectedFiles}
                                        bucket={bucket}
                                    />
                                )}
                                <div className='layout-grid-folder-list  mx-[20px] !p-8'>
                                    {awsFolders && awsFolders.data &&
                                        awsFiles.data &&
                                        awsFiles.data.files &&
                                        awsFiles.data.files.length > 0 && awsFiles.data.files.map((file) => {
                                            const fileExtension = file.name.split('.').pop();
                                            const fileIcon = renderFileIcon(fileExtension as string);
                                            const existFolder = awsFolders.data?.folders.find((folder) => folder.mainFileName === `${file.name}`);
                                            console.log(existFolder)
                                            console.log(fileIcon)

                                            return (
                                                <CardItem
                                                    imageName={
                                                        existFolder ? `data:image/jpeg;base64,${existFolder.thumbnail}` : fileIcon as string
                                                    }
                                                    title={file.name}
                                                    buttonNames={existFolder ? ['</>'] : []}
                                                    buttonEvents={[() => handleGenerateEmbedCode(existFolder?.name as string)]}
                                                    onDelete={() => { }}
                                                    onClickToCard={() => {
                                                        if (existFolder) {
                                                            handleEmbedCodeAndOpen(existFolder.name as string);
                                                          } else {
                                                            if (file.name.includes(".zip")) {
                                                                dispatch(fetchAWSIAMFiles({
                                                                    bucketName: bucket,
                                                                    fileName: file.name
                                                                }))
                                                                let oneDriveData: OneDriveFileData = {
                                                                    id: "",
                                                                    name: file.name,
                                                                    size: file.size as number,
                                                                    rootFileName: ""
                                                                }
                                                                handleTranslateFile(oneDriveData);
                                                            }
                                                            if (showTranslationModal !== true) {
                                                                setShowTranslationModal(true);
                                                            }
                                                            setSecondSelectedFiles((prevSelectedFiles) => {
                                                                const condition = prevSelectedFiles.find(
                                                                    (selectedfile) => selectedfile.name === file.name
                                                                ) === undefined;
                                                                console.log(condition)
                                                                if (condition) {
                                                                    return [...prevSelectedFiles, {
                                                                        name: file.name,
                                                                        size: file.size!,
                                                                    }];
                                                                }
                                                                return prevSelectedFiles;
                                                            });
                                                          }
                                                    }}
                                                />
                                            )

                                        })}
                                </div>
                            </>
                        )}

                        <TextAreaModal
                            show={htmlAreaModal}
                            onHide={() => setHtmlAreaModal(false)}
                            incomingValue={htmlContent}
                            secondOptionalValue=''
                        />

                        {showTranslationModal ? (
                            <MultipleAWSUploadModal
                                fileList={secondSelectedFiles}
                                setFileList={setSecondSelectedFiles}
                                show={showTranslationModal}
                                onHide={() => setShowTranslationModal(false)}
                                bucket={bucket}
                                iamFileList={iamFilesSelector.data ? iamFilesSelector.data : undefined}
                                zipOption={zipOption}
                                setOpenZipOption={setOpenZipOption}
                                zipFile={zipFile}
                                setZipFile={setZipFile}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                )
                
            }
            
        </>
    )
}

export default CombinedAWSComponent