import {  LogoutResponse } from "../../models/auth/logout.model";
import { BaseGetService } from "../base/base.get.service";



export class LogoutService extends BaseGetService<LogoutResponse>{

    constructor(controllerName:string="auth",endpointName:string="logout"){
        super(controllerName,endpointName)
    }
}