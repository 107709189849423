import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetIAMFilesModelResponse,GetAWSIAMFilesRequest, GetBoxIAMFilesResponse, GETBoxIAMFilesRequest } from "../../../models/oneDrive/file/get.iam.files.model";
import { GetIAMFilesService } from "../../../services/aws/get.iam.files.service";
import { GetSubFilesFromZipService } from "../../../services/box/get.sub.files.from.zip.service";




interface GetAllFilesState extends BaseState<GetBoxIAMFilesResponse>{

}


let initialState:GetAllFilesState={
    data:null,
    loading:false,
    error:null
}


export const fetchBoxSubFiles=createAsyncThunk("fetch/IAMFiles",async(model:GETBoxIAMFilesRequest)=>{
    const service=new GetSubFilesFromZipService()
    const response=await service.GetByStringManyParamsAsync([model.bucketName,model.fileName])
    return response
})


export const getBoxSubFilesSlice=createSlice({
    name:"getBoxSubFilesSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchBoxSubFiles.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchBoxSubFiles.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchBoxSubFiles.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getBoxSubFilesSlice.reducer