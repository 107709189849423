'use client'

import React, { useState, useEffect } from "react"

// Section Button Component
function SectionButton({ title, icon, description, onSelect }: { 
  title: string
  icon: React.ReactNode
  description: string
  onSelect: () => void 
}) {
  return (
    <button
      onClick={onSelect}
      className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 p-8 text-center w-full group"
    >
      <div className="mb-6 flex justify-center">
        <div className="text-[#FF6B6B] transform group-hover:scale-110 transition-transform duration-200">
          {icon}
        </div>
      </div>
      <h2 className="text-xl font-medium mb-3 text-gray-800">{title}</h2>
      <p className="text-gray-600 text-sm leading-relaxed">
        {description}
      </p>
    </button>
  )
}

// About Company Content
function AboutCompanyContent() {
  return (
    <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-sm p-8 space-y-8">
      <div className="border-b pb-6">
        <h2 className="text-3xl font-semibold text-gray-900 mb-4">About Our Company</h2>
        <p className="text-gray-600 leading-relaxed">
          VEEWER revolutionizes product catalogs by adding a new dimension. With 3D technology, we present products more impressively and interactively. Customers can examine products 360 degrees, zoom in, and interact with them, enhancing the shopping experience and increasing customer trust and engagement.
        </p>
      </div>
      
      <div className="grid md:grid-cols-2 gap-8">
        <div className="space-y-4">
          <h3 className="text-xl font-medium text-gray-900">Our Challenge</h3>
          <p className="text-gray-600 leading-relaxed">
            Traditional product catalogs struggle to showcase product's full potential and details. 2D images fail to accurately represent the actual size, functionality, and features of products. Customers cannot explore and interact with these catalogs in detail, leading to dissatisfaction and low sales conversion rates.
          </p>
        </div>
        
        <div className="space-y-4">
          <h3 className="text-xl font-medium text-gray-900">Our Solution</h3>
          <p className="text-gray-600 leading-relaxed">
            VEEWER adds a new dimension to product catalogs. With 3D technology, products are presented more impressively and interactively. Customers can examine products 360 degrees, zoom in, and interact with them, enhancing the shopping experience and increasing customer trust and engagement with products.
          </p>
        </div>
      </div>
    </div>
  )
}

// Customer Benefits Content
function CustomerBenefitsContent() {
  const benefits = [
    "Your customers will better understand your products and make purchasing decisions with confidence.",
    "Interactive and detailed product exploration drives higher purchase rates.",
    "Stand out in your industry and stay ahead of the competition with VEEWER.",
    "A more cost-effective and eco-friendly solution compared to traditional catalogs, protecting your business budget."
  ]

  return (
    <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-sm p-8">
      <h2 className="text-3xl font-semibold text-gray-900 mb-8">Customer Benefits</h2>
      <ul className="space-y-4">
        {benefits.map((benefit, index) => (
          <li key={index} className="flex items-start">
            <svg className="w-6 h-6 text-green-500 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            <span className="text-gray-600">{benefit}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

// Video Modal Component
function VideoModal({ videoUrl, title, isOpen, onClose }: { videoUrl: string; title: string; isOpen: boolean; onClose: () => void }) {
  if (!isOpen) return null;

  // Extract video ID from YouTube URL
  const videoId = videoUrl.split('v=')[1] || videoUrl.split('/').pop();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full h-full max-w-4xl">
        <div className="flex justify-between items-center p-6 border-b">
          <h3 className="text-2xl font-semibold text-gray-900">{title}</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="p-6 h-5/6">
          <div className="aspect-w-16 aspect-h-9 h-full">
            <iframe
              src={`https://www.youtube.com/embed/${videoId}`}
              title={title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-full"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

// How to Use Content
function HowToUseContent() {
  const [activeVideo, setActiveVideo] = useState<{ url: string; title: string } | null>(null);

  const videos = [
    { url: "https://youtu.be/3m_SoQGBKkk", title: "How do I use the Embed Code?" },
    { url: "https://youtu.be/5E8O0erhZWs", title: "How to create a configurator with VEEWER?" },
    { url: "https://youtu.be/1xVv8dBv2FY", title: "Comprehensive VEEWER Tutorial" }
  ];

  return (
    <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-sm p-8">
      <h2 className="text-3xl font-semibold text-gray-900 mb-8">How to Use Guide</h2>
      <div className="space-y-8">
        <div>
          <h3 className="text-xl font-medium text-gray-900 mb-4">Video Tutorials</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {videos.map((video) => (
              <button
                key={video.url}
                onClick={() => setActiveVideo(video)}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
              >
                {video.title}
              </button>
            ))}
          </div>
        </div>
        <div>
          <h3 className="text-xl font-medium text-gray-900 mb-4">Quick Start Guide</h3>
          <ol className="list-decimal list-inside space-y-2 text-gray-600">
            <li>Upload your 3D model</li>
            <li>Customize your viewer settings</li>
            <li>Generate and copy the embed code</li>
            <li>Paste the embed code into your website</li>
          </ol>
        </div>
      </div>
      {activeVideo && (
        <VideoModal
          videoUrl={activeVideo.url}
          title={activeVideo.title}
          isOpen={!!activeVideo}
          onClose={() => setActiveVideo(null)}
        />
      )}
    </div>
  )
}

// Main Component
export default function Component() {
  const [selectedSection, setSelectedSection] = useState<string | null>(null)

  const handleSectionSelect = (section: string) => {
    setSelectedSection(section)
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      })
    }, 100)
  }

  useEffect(() => {
    if (selectedSection) {
      setTimeout(() => {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth'
        })
      }, 100)
    }
  }, [selectedSection])

  return (
    <div className="min-h-screen bg-white">
      <main>
        {/* Hero section with background pattern */}
        <div className="relative bg-gray-50 py-16 md:py-24">
          {/* Background pattern */}
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute left-0 top-0 h-96 w-96 opacity-5">
              <svg viewBox="0 0 100 100" className="h-full w-full">
                <path d="M65,0 Q90,25 100,50 T90,75 T65,100 T35,75 T10,50 T35,25 T65,0" fill="currentColor" />
              </svg>
            </div>
            <div className="absolute right-0 bottom-0 h-96 w-96 opacity-5 transform rotate-180">
              <svg viewBox="0 0 100 100" className="h-full w-full">
                <path d="M65,0 Q90,25 100,50 T90,75 T65,100 T35,75 T10,50 T35,25 T65,0" fill="currentColor" />
              </svg>
            </div>
          </div>

          {/* Content */}
          <div className="relative mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl font-bold mb-4 text-gray-900">User Guide</h1>
              <p className="text-xl text-gray-600 mb-8">Help is just a click away!</p>
            </div>
          </div>
        </div>

        {/* Section Buttons */}
        <div className="mx-auto px-4 py-12">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            <SectionButton
              title="About Company"
              description="Learn about VEEWER's revolutionary 3D product catalog solution."
              icon={
                <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"/>
                </svg>
              }
              onSelect={() => handleSectionSelect("about")}
            />
            <SectionButton
              title="Customer Benefits"
              description="Discover the advantages of using VEEWER for your product catalogs."
              icon={
                <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"/>
                </svg>
              }
              onSelect={() => handleSectionSelect("benefits")}
            />
            <SectionButton
              title="How to Use"
              description="Get started with VEEWER and learn how to create your 3D product catalog."
              icon={
                <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253"/>
                </svg>
              }
              onSelect={() => handleSectionSelect("howto")}
            />
          </div>
        </div>

        {/* Content Section */}
        <div className="mx-auto px-4 py-12">
          {selectedSection === "about" && <AboutCompanyContent />}
          {selectedSection === "benefits" && <CustomerBenefitsContent />}
          {selectedSection === "howto" && <HowToUseContent />}
          {!selectedSection && (
            <div className="text-center text-gray-500">
              <p>Select a section above to view content.</p>
            </div>
          )}
        </div>
      </main>
    </div>
  )
}