import React, { useEffect, useState } from 'react';
import { BiMenuAltRight } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/navbar/navbar.scss';
import VeewerLogo from '../../assets/Veewer siyah @4x.png'
import { LogoutService } from '../../services/auth/logout.service';
import { LoginResponse } from '../../models/auth/login.model';
import { AdminLoginResponseModel } from '../../models/admin/admin.login.model';
 
interface HeaderProps {
  setIsBox: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}
 
const SecondHeader: React.FC<HeaderProps> = ({ setIsBox }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  const loginInfo = sessionStorage.getItem('login') as string;
  const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse;
  const adminLoginInfo = sessionStorage.getItem('adminLogin') as string;
  const jsonAdminLoginInfo = JSON.parse(adminLoginInfo) as AdminLoginResponseModel
 
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);
 
  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };
 
  function removeSessionToExternalAuth(key: string) {
    if (sessionStorage.getItem(key) !== null) {
      sessionStorage.removeItem(key);
    }
  }
 
  return (
    <header className='header'>
      <div className='header__content'>
        <Link
          to={'/'}
          className='header__content__logo'
        >
          <img
            src={VeewerLogo}
            alt='logo'
            width='120'
            height='120'
            className='d-inline-block'
          />
        </Link>
        <nav
          className={`header__content__nav ${menuOpen && size.width < 768 ? `isMenu` : ''}`}
        >
          <ul>
            <li className='nav-link'>
              <Link to={'/'}              
              style={{color:`${menuOpen && size.width < 768 ? 'white' : 'black' }`}}><b>Home</b></Link>
            </li>

             {/* ((adminLoginInfo===undefined || adminLoginInfo===null) && (jsonAdminLoginInfo===undefined || jsonAdminLoginInfo===null))  */}
            {loginInfo && jsonLoginInfo.isAuthenticated ? (
              <li className='nav-link'>
                <Link to={'/models'} style={{color:`${menuOpen && size.width < 768 ? 'white' : 'black' }`}}><b>Storage</b></Link>
              </li>
            ) : (<></>)
            }

            {/* ((adminLoginInfo===undefined || adminLoginInfo===null) && (jsonAdminLoginInfo===undefined || jsonAdminLoginInfo===null))  */}
            {
              loginInfo && jsonLoginInfo.isAuthenticated && (
                <li className='nav-link'>
                  <Link to={'/profile'}                 style={{color:`${menuOpen && size.width < 768 ? 'white' : 'black' }`}}>
 
                    <b>Profile</b>
                  </Link>
                </li>
              )
            }
 
            <li className='nav-link'>
              {
                loginInfo && jsonLoginInfo.isAuthenticated ? (
                  <a
                  style={{
                    color:'black'
                  }}
                    onClick={async () => {
                      // const logOutService = new LogoutService();
                      // const response = await logOutService.GetByStringParamAsync(jsonLoginInfo.uid)
 
                      // if (response !== null) {
 
                      //   removeSessionToExternalAuth("microsoft-auth");
                      //   removeSessionToExternalAuth("google-auth")
                      //   removeSessionToExternalAuth("box-auth")
 
 
                      //   sessionStorage.removeItem('login');
                      //   sessionStorage.removeItem('b')
                      //   window.location.replace('/');
                      // }

                      // if((adminLoginInfo!==undefined || adminLoginInfo!==null) && 
                      // (jsonAdminLoginInfo!==undefined || jsonAdminLoginInfo!==null)){
                      //   sessionStorage.removeItem("adminLogin")
                      //   window.location.replace('/')
                        
                      // }

                      // else{
                        const logOutService = new LogoutService();
                        const response = await logOutService.GetByStringParamAsync(jsonLoginInfo.uid)
  
                        if (response !== null) {
  
                          removeSessionToExternalAuth("microsoft-auth");
                          removeSessionToExternalAuth("google-auth")
                          removeSessionToExternalAuth("box-auth")
  
  
                          sessionStorage.removeItem('login');
                          sessionStorage.removeItem('b')
                          window.location.replace('/');

                        }
                    }}
                  >
                    <b>Logout</b>
                  </a>
                ) : (<a onClick={() => {
                  navigate("/login")
                }}
                style={{color:`${menuOpen && size.width < 768 ? 'white' : 'black' }`}}>
 
                  <b>Sign In</b>
                </a>)
              }
 
            </li>
            <li className='nav-link'>
              <Link
                to={"/examples"}
                style={{color:`${menuOpen && size.width < 768 ? 'white' : 'black' }`}}>
              <b>Examples</b> </Link>
            </li>
            <li className='nav-link'>
              <Link
                to={"/tutorials"}
                style={{color:`${menuOpen && size.width < 768 ? 'white' : 'black' }`}}>
              <b>Tutorials</b> </Link>
            </li>
            <li className='nav-link'>
              <Link to={"/privacy"}
              style={{color:`${menuOpen && size.width < 768 ? 'white' : 'black' }`}}>
                  <b>Privacy</b></Link>
            </li>
 
           
          </ul>
        </nav>
        <div className='header__content__toggle'>
          {!menuOpen ? (
            <BiMenuAltRight onClick={menuToggleHandler} />
          ) : (
            <AiOutlineClose onClick={menuToggleHandler} style={{color:'white'}} />
          )}
        </div>
      </div>
    </header>
  );
}
 
export default SecondHeader;