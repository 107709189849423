'use client'

import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import SaveIcon from '@mui/icons-material/Save'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Select, MenuItem, InputLabel, FormControl, SelectChangeEvent } from '@mui/material'

// Import all necessary icons
import SectionAnalysisIcon from '../../assets/SectionAnalysis.png'
import PanIcon from '../../assets/Pan.png'
import ZoomIcon from '../../assets/Zoom.png'
import PropertiesIcon from '../../assets/Properties.png'
import OrbitIcon from '../../assets/Orbit.png'
import SettingsIcon from '../../assets/Settings.png'
import ModelBrowserIcon from '../../assets/ModelBrowser.png'
import FirstPersonIcon from '../../assets/FirstPerson.png'
import CamInstructionsIcon from '../../assets/CamInstructions.png'
import ExplodeModelIcon from '../../assets/ExplodeModel.png'
import MeasureIcon from '../../assets/Measure.png'
import FullScreenIcon from '../../assets/FullScreen.png'
import Cube from '../../assets/Cube.png'
import HomeButton from '../../assets/homeButton.png'

import { UpdateModelIframeCSSButtonService } from '../../services/uploadedObject/update.iframeCSSButton.service'
import { useAppDispatch } from '../../store'
import { GetEmbedCodeInfoService } from '../../services/uploadedObject/get.embedCodeInfo.service'
import { getUploadedObjects } from '../../store/features/object/get.list.uploadedObject.slice'

interface ToolIcon {
  name: string
  css: string
  icon: string
  active: boolean
}

const lightPresetMap = {
  "Simple Grey": 0,
  "Sharp Highlights": 1,
  "Dark Sky": 2,
  "Grey Room": 3,
  "Photo Booth": 4,
  "Tranquility": 5,
  "Infinity Pool": 6,
  "Simple White": 7,
  "Riverbank": 8,
  "Contrast": 9,
  "Rim Highlights": 10,
  "Cool Light": 11,
  "Warm Light": 12,
  "Soft Light": 13,
  "Grid Light": 14
}

export default function EmbedCode() {
  const location = useLocation()
  const initialEmbedCode = location.state?.embedCode || ''
  const initialWebSite = location.state?.webSite || ''
  const [newEmbedCode, setNewEmbedCode] = useState<string>(initialEmbedCode)
  const [width, setWidth] = useState<string>("750px")
  const [height, setHeight] = useState<string>("500px")
  const [showIframe, setShowIframe] = useState<boolean>(true)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [toolIcons, setToolIcons] = useState<ToolIcon[]>([
    { name: 'Section Analysis', css: 'toolbar-sectionTool', icon: SectionAnalysisIcon, active: false },
    { name: 'Pan', css: 'toolbar-panTool', icon: PanIcon, active: false },
    { name: 'Zoom', css: 'toolbar-zoomTool', icon: ZoomIcon, active: false },
    { name: 'Properties', css: 'toolbar-propertiesTool', icon: PropertiesIcon, active: false },
    { name: 'Orbit', css: 'toolbar-orbitTools', icon: OrbitIcon, active: false },
    { name: 'Settings', css: 'toolbar-settingsTool', icon: SettingsIcon, active: false },
    { name: 'Model Browser', css: 'toolbar-modelStructureTool', icon: ModelBrowserIcon, active: false },
    { name: 'First Person', css: 'toolbar-bimWalkTool', icon: FirstPersonIcon, active: false },
    { name: 'Cam Instructions', css: 'toolbar-cameraSubmenuTool', icon: CamInstructionsIcon, active: false },
    { name: 'Explode Model', css: 'toolbar-explodeTool', icon: ExplodeModelIcon, active: false },
    { name: 'Measure', css: 'toolbar-measurementSubmenuTool', icon: MeasureIcon, active: false },
    { name: 'Full Screen', css: 'toolbar-fullscreenTool', icon: FullScreenIcon, active: false },
    { name: 'ViewCube', css: 'viewcube', icon: Cube, active: false },
    { name: 'ViewHome', css: 'homeViewWrapper', icon: HomeButton, active: false },
  ])
  const [website, setWebsite] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const b = sessionStorage.getItem('b') as string
  const [lightPreset, setLightPreset] = useState<string>("Simple Grey")
  const [backgroundColor, setBackgroundColor] = useState<string>("#FFFFFF")
  const [groundShadow, setGroundShadow] = useState<boolean>(false)
  const [groundReflection, setGroundReflection] = useState<boolean>(false)

  useEffect(() => {
    const service = new GetEmbedCodeInfoService()
    const srcMatch = initialEmbedCode.match(/src=['"]([^'"]*)['"]/);
    if (srcMatch) {
      const srcValue = srcMatch[1]
      const splittedSrc = srcValue.split("/")
      const urn = splittedSrc[7]
      service.GetByStringParamAsync(urn).then((data) => {
        setNewEmbedCode(data.iframeCssButton)
        setWebsite(data.webSite)
        
        // Parse the embed code and set initial states
        const embedCodeSrc = data.iframeCssButton.match(/src=['"]([^'"]*)['"]/)?.[1] || ''
        const [, propertiesString] = embedCodeSrc.split('/properties/')
        const properties = new URLSearchParams(propertiesString || '')

        // Set width and height
        const widthMatch = data.iframeCssButton.match(/width=['"](\d+px)['"]/);
        const heightMatch = data.iframeCssButton.match(/height=['"](\d+px)['"]/);
        if (widthMatch) setWidth(widthMatch[1])
        if (heightMatch) setHeight(heightMatch[1])
  
        // Set light preset
        const lightPresetValue = properties.get('lightPreset')
        if (lightPresetValue) {
          const presetNumber = parseInt(lightPresetValue)
          const presetName = Object.keys(lightPresetMap).find(
            (key) => lightPresetMap[key as keyof typeof lightPresetMap] === presetNumber
          ) || "Simple Grey"
          setLightPreset(presetName)
        }
  
        // Set background color
        const backgroundColorValue = properties.get('backgroundColor')
        if (backgroundColorValue) {
          setBackgroundColor(`#${backgroundColorValue}`)
        }

        // Set ground shadow
        setGroundShadow(properties.get('setGroundShadow') === 'true')

        // Set ground reflection
        setGroundReflection(properties.get('setGroundReflection') === 'true')

        // Set tool icons
        const activeToolsString = properties.get('activeTools') || ''
        const activeTools = activeToolsString.split(',')
        setToolIcons((prevIcons) =>
          prevIcons.map((icon) => ({
            ...icon,
            active: activeTools.includes(icon.css),
          }))
        )
      })
    }
  }, [initialEmbedCode])
  
  const updateProperty = (propertyName: string, value: string) => {
    setNewEmbedCode(prevCode => {
      const srcMatch = prevCode.match(/src=['"]([^'"]*)['"]/);
      if (srcMatch) {
        let srcValue = srcMatch[1]
        const [baseUrl, propertiesString] = srcValue.split('/properties/')
        const properties = new URLSearchParams(propertiesString || '')
        
        if (value) {
          properties.set(propertyName, value)
        } else {
          properties.delete(propertyName)
        }

        const newSrc = `${baseUrl}/properties/${properties.toString()}`
        return prevCode.replace(/src=['"]([^'"]*)['"]/,  `src='${newSrc}'`)
      }
      return prevCode
    })
  }

  const updateToolIcons = (newToolIcons: ToolIcon[]) => {
    const activeTools = newToolIcons.filter(icon => icon.active).map(icon => icon.css).join(',')
    updateProperty('activeTools', activeTools)
  }

  const handleToggleIcon = (index: number) => {
    setToolIcons(prevIcons => {
      const newIcons = prevIcons.map((icon, i) => 
        i === index ? { ...icon, active: !icon.active } : icon
      )
      updateToolIcons(newIcons)
      return newIcons
    })
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(newEmbedCode)
      .then(() => {
        Swal.fire({
          title: 'Copied!',
          text: 'Embed code copied to clipboard',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false
        })
      })
      .catch(() => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to copy embed code',
          icon: 'error'
        })
      })
  }

  const handleBackgroundColorChange = (color: any) => {
    const newColor = color.target.value.replace('#', '')
    setBackgroundColor(`#${newColor}`)
    updateProperty('backgroundColor', newColor)
  }
  
  const handleSavingSettings = async () => {
    setIsLoading(true)
    const service = new UpdateModelIframeCSSButtonService()
    const srcMatch = newEmbedCode.match(/src=['"]([^'"]*)['"]/);
    if (srcMatch) {
      const srcValue = srcMatch[1]
      const splittedSrc = srcValue.split("/")
      const urn = splittedSrc[7]
      try {
        const data = await service.PostAsync({
          iframeCSSButton: newEmbedCode,
          webSite: website,
          urn: urn
        })
        if (data.isSuccess) {
          const service = new GetEmbedCodeInfoService()
          const updatedResponse = await service.GetByStringParamAsync(urn)
          setNewEmbedCode(updatedResponse.iframeCssButton)
          setWebsite(updatedResponse.webSite)
          dispatch(
            getUploadedObjects({
              bucketKey: b
            })
          )
          Swal.fire({
            title: 'Success!',
            text: 'Settings saved successfully',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false
          })
        }
      } catch (error) {
        console.log(error)
        Swal.fire({
          title: 'Error',
          text: 'Failed to save settings',
          icon: 'error'
        })
      } finally {
        setIsLoading(false)
      }
    } else {
      console.log("src not found")
      setIsLoading(false)
      Swal.fire({
        title: 'Error',
        text: 'Source URL not found in embed code',
        icon: 'error'
      })
    }
  }

  const handleUpdateIframe = () => {
    setShowIframe(true)
    if (iframeRef.current) {
      iframeRef.current.src = iframeRef.current.src
    }
  }

  const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newWidth = e.target.value
    setWidth(newWidth)
    setNewEmbedCode(prevCode => 
      prevCode.replace(/width=['"][\d.]+(?:px|%)?['"]/, `width='${newWidth}'`)
    )
  }

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHeight = e.target.value
    setHeight(newHeight)
    setNewEmbedCode(prevCode => 
      prevCode.replace(/height=['"][\d.]+(?:px|%)?['"]/, `height='${newHeight}'`)
    )
  }

  const handleLightPresetChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as keyof typeof lightPresetMap
    setLightPreset(value)
    updateProperty('lightPreset', lightPresetMap[value].toString())
  }

  const handleGroundShadowChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked
    setGroundShadow(newValue)
    updateProperty('setGroundShadow', newValue ? 'true' : '')
  }

  const handleGroundReflectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked
    setGroundReflection(newValue)
    updateProperty('setGroundReflection', newValue ? 'true' : '')
  }

  return (
    <div className="p-8 relative">
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
        </div>
      )}
      <div className="max-w-8xl mx-auto overflow-hidden">
        <div className="p-8">
          <h1 className="text-4xl font-bold text-center mb-8 text-gray-800">Veewer Embed Code View</h1>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="space-y-6">
              <div>
                <InputLabel htmlFor="website">Website URL</InputLabel>
                <input
                  id="website"
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition"
                  placeholder="Enter website URL"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  disabled={isLoading}
                />
              </div>

              <div className='relative'>
                <InputLabel htmlFor="embedCode">IFrame Embed Code</InputLabel>
                <textarea
                  
                  id="embedCode"
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition"
                  value={newEmbedCode}
                  onChange={(e) => setNewEmbedCode(e.target.value)}
                  rows={4}
                  disabled={isLoading}
                />
                <button
                  className="absolute right-0 top-0"
                  onClick={handleSavingSettings}
                  disabled={isLoading}
                >
                  <SaveIcon className='text-black hover:!text-green-600 transition duration-200 ease-in-out transform'/>
                </button>

                <button
                  className="absolute right-8 top-0"
                  onClick={handleCopy}
                  disabled={isLoading}
                >
                  <ContentCopyIcon className='text-black hover:!text-blue-600  transition duration-200 ease-in-out transform'/>
                </button>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <InputLabel htmlFor="width">Width</InputLabel>
                  <input
                    id="width"
                    className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition"
                    value={width}
                    onChange={handleWidthChange}
                    disabled={isLoading}
                  />
                </div>
                <div>
                  <InputLabel htmlFor="height">Height</InputLabel>
                  <input
                    id="height"
                    className="w-full p-3 border border-gray-300 rounded-md  focus:ring-2 focus:ring-blue-500 focus:border-transparent transition"
                    value={height}
                    onChange={handleHeightChange}
                    disabled={isLoading}
                  />
                </div>
              </div>

              <FormControl fullWidth>
                <InputLabel id="light-preset-label">Light Preset</InputLabel>
                <Select
                  labelId="light-preset-label"
                  id="light-preset"
                  value={lightPreset}
                  onChange={handleLightPresetChange}
                  disabled={isLoading}
                >
                  {Object.keys(lightPresetMap).map((preset) => (
                    <MenuItem key={preset} value={preset}>
                      {preset}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div>
                <InputLabel htmlFor="backgroundColor">Background Color</InputLabel>
                <input
                  type="color"
                  id="backgroundColor"
                  value={backgroundColor}
                  onChange={handleBackgroundColorChange}
                  className="w-10 h-10 p-0 border-0 rounded-md cursor-pointer"
                />
                <span className="ml-2">{backgroundColor}</span>
              </div>

              <div>
                <InputLabel htmlFor="groundShadow">Ground Shadow</InputLabel>
                <input
                  type="checkbox"
                  id="groundShadow"
                  checked={groundShadow}
                  onChange={handleGroundShadowChange}
                />
              </div>
              <div>
                <InputLabel htmlFor="groundReflection">Ground Reflection</InputLabel>
                <input
                  type="checkbox"
                  id="groundReflection"
                  checked={groundReflection}
                  onChange={handleGroundReflectionChange}
                />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-4 text-gray-800">Tool Icons</h3>
                <div className="flex flex-wrap gap-3">
                  {toolIcons.map((tool, index) => (
                    <button
                      key={index}
                      className={`rounded-lg transition-all flex items-center justify-center duration-200 ease-in-out transform hover:scale-110 ${tool.active ? 'bg-gray-200 shadow-lg' : 'bg-blue-500 hover:bg-gray-300'
                        }`}
                      onClick={() => handleToggleIcon(index)}
                      title={tool.name}
                      disabled={isLoading}
                    >
                      <img src={tool.icon} alt={tool.name} className={`w-16 h-16 ${tool.active ? 'border-3 border-gray-400':'border-2  border-green-500'}`} />
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {showIframe && (
              <div className="bg-gray-100 p-6 rounded-lg relative">
                <h2 className="text-2xl font-semibold text-gray-800">Iframe Preview</h2>
                <button
                  className="bg-black px-2 py-1 rounded my-2 z-50 flex text-white hover:bg-blue-700 transition duration-200 ease-in-out transform hover:scale-105"
                  onClick={handleUpdateIframe}
                  disabled={isLoading}
                >
                 Refresh
                </button>
                <div className="relative rounded-lg overflow-hidden" style={{ width, height }}>
                  <iframe
                    ref={iframeRef}
                    src={newEmbedCode.match(/src=['"]([^'"]*)['"]/)?.[1] || website}
                    width={width}
                    height={height}
                  />
                  {(!newEmbedCode.match(/src=['"]([^'"]*)['"]/)?.[1] && !website) && (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-200 text-gray-500">
                      No URL provided
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}