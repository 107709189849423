import { GETBoxIAMFilesRequest, GetBoxIAMFilesResponse } from "../../models/oneDrive/file/get.iam.files.model";
import { BaseGetService } from "../base/base.get.service";


export class GetSubFilesFromZipService extends BaseGetService<GetBoxIAMFilesResponse>{

    constructor(controllerName:string="box", endpoint:string="getSubFilesFromZip"){
        super(controllerName, endpoint)
    }

}