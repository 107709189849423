import sync3dLogo from '../../assets/footer sync3D logo.png';
import codeoLogoWhite from '../../assets/home/codeoLogoWhite.png';
import VeewerLogo from '../../assets/Veewer_white_logoo.png'
import '../../styles/footer/footer.css';
import facebook from '../../assets/footer/Facebook.svg';
import instagram from '../../assets/footer/Instagram.svg';
import X from '../../assets/footer/X.svg';
import LinkedIn from '../../assets/footer/LinkedIn.svg';
import Youtube from '../../assets/footer/Youtube.svg';
import { Link } from 'react-router-dom';
import codeoSolutions from '../../assets/home/Codeo Solutions Logo.png'

export default function Footer() {
	let currentDate = new Date();
	let currentYear = currentDate.getFullYear();

	return (
		<>
			<footer className='footer'>
				<div className='footer-container'>
					<div className='ft-left flex item-center justify-centers'>
						<a href='/'>
							<img
								className='ft-logo-sync'
								src={VeewerLogo}
								alt='sync3d-logo'
							/>
							
						</a>
					

						<span className='text-4xl text-[#2596be]'>X</span>
						<a href='https://www.codeosolutions.com/'>
							<img
								className='ft-logo codeo'
								src={codeoLogoWhite}
								alt=''
							/>
						</a>
						<a href='/' hidden={true}>
							<img
								className='ft-logo-sync'
								src={codeoSolutions}
								alt='sync3d-logo'
								hidden={true}
							/>
							
						</a>
					</div>
					<div className='ft-right'>
						<ColumnSocial />
					</div>
				</div>
				<hr className='divider' />
				<div className='ft-bottom'>
					<p className='ft-text'>
						© {currentYear} Veewer. All rights reserved.
					</p>
					<div className='tos'>
						{/* <a
							href='/privacy'
							className='ft-link'
						>
							Privacy Policy
						</a> */}
						<Link to={"/privacy"} className='ft-link'>Privacy Policy</Link>
						<a
							href='/'
							className='ft-link'
						>
							Terms of Use
						</a>
					</div>
				</div>
			</footer>
		</>
	);
}

type SocialPlatform =
	| 'Facebook'
	| 'Instagram'
	| 'Twitter'
	| 'LinkedIn'
	| 'Youtube';

type SocialLinkProps = {
	platform: SocialPlatform;
	link: string;
	label: string;
	icon: string;
};

function SocialLink({ link, label, icon }: SocialLinkProps) {
	return (
		<div className='ft-link-group'>
			<img
				className='img-socials'
				src={icon}
				alt=''
			/>
			<a
				href={link}
				className='ft-link'
			>
				{label}
			</a>
		</div>
	);
}

function ColumnSocial() {
	const socialLinks: SocialLinkProps[] = [
		{
			platform: 'Facebook',
			link: 'https://www.facebook.com/CodeoEngineering/',
			label: 'Facebook',
			icon: facebook,
		},
		{
			platform: 'Instagram',
			link: 'https://www.instagram.com/codeoengineering/',
			label: 'Instagram',
			icon: instagram,
		},
		{
			platform: 'Twitter',
			link: 'https://twitter.com/codeoeng',
			label: 'Twitter',
			icon: X,
		},
		{
			platform: 'LinkedIn',
			link: 'https://www.linkedin.com/company/codeo-turkey/mycompany/',
			label: 'LinkedIn',
			icon: LinkedIn,
		},
		{
			platform: 'Youtube',
			link: 'https://www.youtube.com/@codeo3496',
			label: 'Youtube',
			icon: Youtube,
		},
	];

	return (
		<div className='links-column follow'>
			<h5 className='ft-title'>Follow Us</h5>
			<div className='links-column socials'>
				{socialLinks.map((link, index) => (
					<SocialLink
						key={index}
						platform={link.platform}
						link={link.link}
						label={link.label}
						icon={link.icon}
					/>
				))}
			</div>
		</div>
	);
}
