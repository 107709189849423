import { UploadBoxPrevFilesRequestModel, UploadBoxPrevFilesResponseModel } from "../../models/box/upload.prev.box.files.model";
import { BasePutService } from "../base/base.put.service";



export class UploadPrevBoxFilesService extends 
BasePutService<UploadBoxPrevFilesRequestModel,UploadBoxPrevFilesResponseModel>{

    constructor(controllerName:string="box",endpoint:string="upload"){
        super(controllerName,endpoint)
    }

    async UploadWithFormDataAsync(request:UploadBoxPrevFilesRequestModel){
        const formData=new FormData()
        request.files.map((file,i)=>{
            formData.append(`files`, file)
        })

        formData.append("bucketName", request.bucketName)

        
       
        return await this.PutWithFormDataAsync(formData)
    }
}