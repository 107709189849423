import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { PutUploadedObjectRequestModel } from "../../../models/object/uploadedObject/put.uploadedObject.request.model";
import { PutUploadedObjectResponseModel } from "../../../models/object/uploadedObject/put.uploadedObject.response.model";
import { PutUploadedObjectService } from "../../../services/uploadedObject/put.uploadedObject.service";




interface CreateUploadedObjectState extends BaseState<PutUploadedObjectResponseModel>{

}


let initialState:CreateUploadedObjectState={
    data:null,
    error:null,
    loading:false
}



export const createUploadedObject=createAsyncThunk("uploadedObject/create",
async(request:PutUploadedObjectRequestModel,thunkAPI)=>{
    try{
        const service=new PutUploadedObjectService("objects","multiple")
        console.log(request.files)
        const response=await service.UploadObjectAsync(request)
        return response
    }
    catch(err:any){
        console.log(err)
        if (!err.response) {
            throw err;
          }   
          const errorData = err.response.data;
          console.log(errorData)
          return thunkAPI.rejectWithValue({
            error: errorData.error ? errorData.error : (
                errorData.errorData? `${errorData.errorFrom}  ${errorData.statusName}`:errorData.message
            )
          });
    }
   
})


export const createUploadedObjectSlice=createSlice({
    name:"createUploadedObjectSlice",
    initialState,
    reducers:{
        resetCreateState:(state)=>{
            state.data = null;
            state.loading = false;
            state.error = null;
        }
    },
    extraReducers(builder){
        builder
        .addCase(createUploadedObject.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(createUploadedObject.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(createUploadedObject.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
        
    }
})


export const { resetCreateState } = createUploadedObjectSlice.actions;
export default createUploadedObjectSlice.reducer











