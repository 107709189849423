import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BaseState } from '../../../commons/base.state';
import { GetUserListModel } from '../../../models/admin/get.userlist.model';
import { GetUserListService } from '../../../services/admin/get.userlist.service';

interface GetUserListState extends BaseState<GetUserListModel[]> { }

let initialState: GetUserListState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchgetUserList = createAsyncThunk(
  'fetch/getUserList',async () => {
    const service=new GetUserListService()
    const response=await service.GetAsync()
    return response
  }
);

export const getUserListSlice = createSlice({
  name: 'getUserListSlice',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchgetUserList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchgetUserList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchgetUserList.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetError } = getUserListSlice.actions;
export default getUserListSlice.reducer;
