import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../../styles/login/login.css';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { fetchPostAuth, resetError } from '../../store/features/auth/auth.post.slice';
import Swal from 'sweetalert2';
import LoadingScreen from '../../utils/LoadingScreen';

export default function Login() {
  const navigate = useNavigate();
  const [userNameOrEmail, setUserNameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [inputType,setInputType]=useState('') // State for email validation error

  const dispatch = useAppDispatch();
  const postAuthSelector = useAppSelector((x: RootState) => x.postAuth);

  const getGoogleTokenSelector = useAppSelector((x: RootState) => x.googleAuthSlice);
  const getMicrosoftTokenSelector = useAppSelector((x: RootState) => x.microsoftAuthSlice);
  const [showPassword, setShowPassword] = useState(false);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  useEffect(() => {
    if (postAuthSelector.data != null && postAuthSelector.loading === false &&
      window.location.href.indexOf('google') === -1 && window.location.href.indexOf('microsoft') === -1) {
      const data = JSON.stringify({
        isAuthenticated: postAuthSelector.data.isAuthenticated,
        expiresAt: postAuthSelector.data.expiresAt,
        isAdmin: postAuthSelector.data.isAdmin,
        isUser: postAuthSelector.data.isUser,
        isOwner: postAuthSelector.data.isOwner,
        uid: postAuthSelector.data.uid,
      });
      sessionStorage.setItem('login', data);
      localStorage.setItem(`isAnyOpenSession-${userNameOrEmail}`,"true")
      navigate('/');
    }
  }, [
    postAuthSelector.data,
    postAuthSelector.error,
    postAuthSelector.loading,
    navigate,
  ]);

  useEffect(() => {
    if (getGoogleTokenSelector.data != null && getGoogleTokenSelector.loading === false &&
      window.location.href.indexOf('google') !== -1 && window.location.href.indexOf('microsoft') === -1) {
      const data = JSON.stringify({
        isAuthenticated: getGoogleTokenSelector.data.isAuthenticated,
        expiresAt: getGoogleTokenSelector.data.expiresAt,
        uid: getGoogleTokenSelector.data.uid,
        microsoft_auth: false,
        google_auth: getGoogleTokenSelector.data.google_auth,
        isAdmin: getGoogleTokenSelector.data.isAdmin,
        isUser: getGoogleTokenSelector.data.isUser,
        isOwner: getGoogleTokenSelector.data.isOwner,
      });
      sessionStorage.setItem('login', data);
      navigate('/');
    }
  }, [
    getGoogleTokenSelector.data,
    getGoogleTokenSelector.error,
    getGoogleTokenSelector.loading,
    navigate,
  ]);

  useEffect(() => {
    if (getMicrosoftTokenSelector.data != null && getMicrosoftTokenSelector.loading === false &&
      window.location.href.indexOf('google') === -1 && window.location.href.indexOf('microsoft') !== -1) {
      const data = JSON.stringify({
        isAuthenticated: getMicrosoftTokenSelector.data.isAuthenticated,
        expiresAt: getMicrosoftTokenSelector.data.expiresAt,
        isAdmin: getMicrosoftTokenSelector.data.isAdmin,
        isUser: getMicrosoftTokenSelector.data.isUser,
        isOwner: getMicrosoftTokenSelector.data.isOwner,
        uid: getMicrosoftTokenSelector.data.uid,
        microsoft_auth: getMicrosoftTokenSelector.data.microsoft_auth,
        google_auth: false,
      });
      
      sessionStorage.setItem('login', data);
      navigate('/');
    }
  }, [
    getMicrosoftTokenSelector.data,
    getMicrosoftTokenSelector.error,
    getMicrosoftTokenSelector.loading,
    navigate,
  ]);

  useEffect(() => {
    if (
      postAuthSelector.error !== null &&
      postAuthSelector.data === null &&
      postAuthSelector.loading === false
    ) {
      const error = postAuthSelector.error;

      if (error.error !== undefined || error.error !== null) {
        Swal.fire('Login failed', error.error, 'error');
      } else {
        Swal.fire('Login failed', error.message, 'error');
      }
      dispatch(resetError())
    }
  }, [
    postAuthSelector.data,
    postAuthSelector.error,
    postAuthSelector.loading,
    Swal,
  ]);

  const validateEmail = (email:string) => {
    
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };

  return (
    <>
      {postAuthSelector.loading ||
        getGoogleTokenSelector.loading ||
        getMicrosoftTokenSelector.loading ? (
      <LoadingScreen textColor='black' text='Attempting to log in to the Veewer' tailwindcss='!text-sm !font-thin !font-extralight ' loadingTime='5s' />
      ) : (
        <form className='signup-form form'>
          <div className='group-flex'>
            <div className='form-group'>
              <label htmlFor='userNameOrEmail'>Username or Email</label>
              <input
                type={inputType}
                value={userNameOrEmail}
                onChange={(e) => {
                  setUserNameOrEmail(e.target.value);
                  if(userNameOrEmail.includes("@") || userNameOrEmail.includes(".com")){
                    setInputType('email');
                    
                  }
                  else{
                    setInputType('text')
                  }   
                }}
                onBlur={()=>{
                  if(inputType === 'email'){
                    validateEmail(userNameOrEmail);
                  }
                }}
                className={`action-input ${emailError ? 'error' : ''}`}
                autoComplete={inputType==="email"?"email":"username"}
              />
              {emailError && <span className='error-message'>{emailError}</span>}
            </div>

            <div className='form-group'>
              <label htmlFor='password'>Password</label>

              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                className='action-input'
              />
              <span
                className='show-password'
                onClick={() => setShowPassword((p) => !p)}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </div>
          </div>

          <div className='action-button-group-flex'>
            <button
              disabled={userNameOrEmail === '' || password === '' || emailError !== ''}
              onClick={(e) => {
                e.preventDefault();
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const isEmail = emailRegex.test(userNameOrEmail);

                if(emailError !== ''){
                  Swal.fire({
                    title: 'Error',
                    text: emailError,
                    icon: 'error',
                    showCloseButton: true,
                    willClose:()=>{
                      setEmailError('')
                      setUserNameOrEmail('')
                      setPassword('')
                      setInputType('')  

                    }
                  });           
                }
                else
                dispatch(
                  fetchPostAuth({
                    userName: isEmail ? '' : userNameOrEmail,
                    email: isEmail ? userNameOrEmail : '',
                    password: password,
                    isAnyOpenSession:(localStorage.getItem(`isAnyOpenSession-${userNameOrEmail}`)==="true" ||
                     localStorage.getItem(`isAnyOpenSession-${userNameOrEmail}`)!==null) && (
                      sessionStorage.getItem("login")===null || sessionStorage.getItem("login")===undefined)?true:false
                  })
                );
              }}
              className='action-button direct-login'
            >
              Sign In
            </button>
            {/* <div className='action-button-group-flex'>
              <button
                className='google-login-button'
                type='button'
                style={{display:"none"}}
                onClick={() => {
                  window.location.assign(`${baseUrl}google/auth/url`);
                }}
              >
                <img
                  src='https://developers.google.com/identity/images/g-logo.png'
                   alt="Google logo"
                />
                <span  style={{ color: '#0078D4' }}>Login with Google</span>
              </button>
            </div>
            <div className='action-button-group-flex'>
              <button
                className='microsoft-login-button'
                type='button'
                onClick={() => {
                  window.location.assign(`${baseUrl}microsoft/auth/url`);
                }}
              >
                <img
                  src='https://docs.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.png'
                  alt='Login with Microsoft'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#ffffff'
                  }}
                />
                <span style={{ color: '#0078D4' }}>Login with Microsoft</span>
              </button>
            </div> */}
          </div>
          <br/>
          {/* <div className='forgot-password-link'>
            <Link to='/forgotPassword' className='hidden'>Forgot Password?</Link>
          </div> */}
        </form>
      )}
    </>
  );
}

