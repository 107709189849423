import { UploadSvfModelRequestModel, UploadSvfModelResponseModel } from "../../../models/oneDrive/file/upload.svf.model";
import { BasePutService } from "../../base/base.put.service";



export class UploadSvfService extends BasePutService<UploadSvfModelRequestModel,UploadSvfModelResponseModel>{
    constructor(controllerName:string="oneDrive",endpoint:string="uploadToSvf"){
        super(controllerName,endpoint)
    }

    async UploadSvfWithFormDataAsync(request:UploadSvfModelRequestModel){
        const formData=new FormData()

       
        formData.append("bucketName",request.bucketName)
        formData.append("urn",request.urn)
        formData.append("userId",request.userId)
        formData.append("mainFileName",request.mainFileName)
 
        return await this.PutWithFormDataAsync(formData)
    }
}