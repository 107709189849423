import '../styles/login/login.css';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SaveCodeService } from '../services/oneDrive/auth/save.code.service';
import SecondHeader from '../components/Navbar/SecondHeader';
import Footer from '../components/Footer/Footer';
import Hero from '../components/Hero';
import Register from '../components/Register';
import { PostUserBucketService } from '../services/bucket/post.user.bucket.service';
import { LoginResponse } from '../models/auth/login.model';
import { BoxPostTokenService } from '../services/auth/box/box.post.token.service';
import { PostGoogleTokenService } from '../services/googleDrive/auth/token.google.service';
import { AWSPostTokenService } from '../services/auth/aws/aws.post.token.service';
import { CreateAWSBucketService } from '../services/aws/create.aws.bucket.service';
import Newsletter from '../components/Newsletter';
import { useAppSelector } from '../store';
import Swal from 'sweetalert2';
import Features from '../components/Features';
import ChallengeSolutionBenefits from '../components/ChallengeSolutionBenefits';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import FileFormats from '../components/MultipleUploadModal/FileFormats';
import CodeoSolutions from "../assets/home/Codeo Solutions Logo.png"
import { AdminLoginResponseModel } from '../models/admin/admin.login.model';
import AdminHome from './AdminHome';

const Home = () => {
	const navigate = useNavigate();
	const loginInfo = sessionStorage.getItem('login') as string;
	const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse;
	const [showSupportedFormats, setShowSupportedFormats] = useState(false);
	const [isBox, setIsBox] = useState(false);

	const betaUser = useAppSelector(s => s.betaUserSlice)
	const subscriber = useAppSelector(s => s.subscriberSlice)

	const betaWindowRef = useRef<HTMLDivElement>(null);

	const scrollToBetaWindow = () => {
		if (betaWindowRef.current) {
			betaWindowRef.current.scrollIntoView({
				behavior: 'smooth',
			});
		}
	};

	useEffect(() => {
		if (loginInfo !== null && (sessionStorage.getItem('b') === undefined || sessionStorage.getItem('b') === null)) {
			const createUserBucketService = new PostUserBucketService();
			// console.log(jsonLoginInfo)
			// console.log(jsonLoginInfo.uid)
			createUserBucketService
				.PostAsync({
					uid: jsonLoginInfo.uid,
				})
				.then((res) => {
					// console.log(res.isSuccess);
					sessionStorage.setItem('b', res.bucket);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [loginInfo]);
	useEffect(() => {
		if (window.location.href.indexOf('?code') !== -1) {
			if (
				sessionStorage.getItem('clickOneDrive') !== null &&
				sessionStorage.getItem('clickOneDrive') === 'true'
			) {
				const code = window.location.href
					.split('/?')[1]
					.split('=')[1]
					.split('&')[0];

				const saveCodeService = new SaveCodeService();
				const b = sessionStorage.getItem('b') as string;
				saveCodeService
					.PostAsync({
						code,
						bucketName: b,
					})
					.then((data) => {
						navigate('');
						sessionStorage.setItem('clickOneDrive', 'false');
						sessionStorage.setItem('microsoft-auth', JSON.stringify(data));
					})
					.catch((error) => {
						console.log(error);
					});
			} else if (
				sessionStorage.getItem('clickBox') !== null &&
				sessionStorage.getItem('clickBox') === 'true'
			) {
				const boxTokenService = new BoxPostTokenService();
				const code = window.location.href
					.split('/?')[1]
					.split('=')[1]
					.split('&')[0];
				const b = sessionStorage.getItem('b') as string;
				boxTokenService
					.PostAsync({
						code,
						bucketName: b,
					})
					.then((data) => {
						navigate('');
						sessionStorage.setItem('clickBox', 'false');
						sessionStorage.setItem('box-auth', JSON.stringify(data));
					})
					.catch((error) => {
						console.log(error);
					});
			} else if (
				sessionStorage.getItem('clickAWS') !== null &&
				sessionStorage.getItem('clickAWS') === 'true'
			) {
				const awsTokenService = new AWSPostTokenService();
				const code = window.location.href
					.split('/?')[1]
					.split('=')[1]
					.split('&')[0];
				const b = sessionStorage.getItem('b') as string;
				awsTokenService

					.PostAsync({
						code,
						bucketName: b,
					})
					.then((data) => {
						const awsCreateBucketService = new CreateAWSBucketService();
						const bucketName = sessionStorage.getItem('b') as string;
						awsCreateBucketService
							.PostAsync({
								bucketName,
							})
							.then(() => {
								navigate('');
								sessionStorage.setItem('clickAWS', 'false');
								sessionStorage.setItem('aws-auth', JSON.stringify(data));
							})
							.catch((error) => {
								console.log(error);
							});
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				console.log("Post google token service 134")
				const googleTokenService = new PostGoogleTokenService();
				const code = window.location.href
					.split('/?')[1]
					.split('=')[1]
					.split('&')[0];
				const b = sessionStorage.getItem('b') as string;
				console.log("Post google token service 140")
				googleTokenService
					.PostAsync({
						code,
						bucketName: b,
					})
					.then((data) => {
						console.log("Post google token service 148")
						navigate('');
						sessionStorage.setItem('clickGoogleDrive', 'false');
						sessionStorage.setItem('google-auth', JSON.stringify(data));
					})
					.catch((error) => {
						console.log(error);
					});
			}
		}
	}, []);

	useEffect(() => {
		if (betaUser.data != null && betaUser.error == null && betaUser.loading == false) {
			Swal.fire("Join our beta program!",
				betaUser.data.message,
				"success")
		}

		else {
			if (betaUser.error !== null) {
				const fireType = betaUser.error.error === "This user joined our beta program" ? "warning" : "error"
				Swal.fire("Join our beta program!",
					betaUser.error.error,
					fireType)
			}
		}

	}, [betaUser.data, betaUser.error, betaUser.loading])

	useEffect(() => {
		if (subscriber.data != null && subscriber.data.isSuccess === true && subscriber.error == null && subscriber.loading == false) {
			Swal.fire("Subscription",
				subscriber.data.message,
				"success")
		}
	}, [subscriber.data, subscriber.error, subscriber.loading])

	const adminLoginInfo = sessionStorage.getItem('adminLogin') as string
	const jsonAdminLoginInfo = JSON.parse(adminLoginInfo) as AdminLoginResponseModel

	return (
		<>
			{
				 adminLoginInfo && jsonAdminLoginInfo.isAuthenticated ? (
					<AdminHome />
				 ) : (
					<div>
				 		<Hero scrollToBeta={scrollToBetaWindow} />
				 		<ChallengeSolutionBenefits />
				 		<Dialog
							open={showSupportedFormats}
							onClose={() => setShowSupportedFormats(false)}
							sx={{
								'& .MuiDialog-paper': {
									width: '80%',
									maxWidth: '600px',
									maxHeight: '80vh',
									'@media (max-width: 600px)': { // xs için media query
										width: '950%', // xs boyutlarında %90 olacak
									},
								},
							}}

						>

							<DialogContent>
								<FileFormats />
							</DialogContent>
							<DialogActions>
								<Button onClick={() => setShowSupportedFormats(false)}>Close</Button>
							</DialogActions>
						</Dialog>

						<Features setShowSupportedFormats={setShowSupportedFormats} showSupportedFormats={showSupportedFormats} />
						<Register ref={betaWindowRef} />
						<Newsletter />
						<Footer />
					</div>
				)
			}
		</>
	);
};
export default Home;
