import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import './App.css';
import './styles/global.scss';
import MainRoutes from './navigators/MainRoutes';
import { LogoutService } from './services/auth/logout.service';
import TawkTo from './components/Tawkto';

function App() {
  return (
    <div className="App">
      <TawkTo/>
      <MainRoutes />
    </div>
  );
}

export default App;
