import React, { Component, useState } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import '../styles/bucketList/cardItem.css';
import CardLoading from './Loading/CardLoading/CardLoading';

interface CardItemProps {
	imageName: string | JSX.Element;
	title?: string;
	text?: string;
	buttonNames: (string | JSX.Element)[];
	buttonEvents: React.MouseEventHandler<HTMLButtonElement>[];
	onDelete: () => void;
	id?: string;
	onClickToCard: () => void;
	setDeleteText?:(value: React.SetStateAction<string>) => void
	tickIcon?:JSX.Element
	loading?:any
}

const buttonColors: string[] = [
	'primary',
	'secondary',
	'success',
	'info',
	'warning',
	'danger',
	'dark',
	'light',
];

const CardItem: React.FC<CardItemProps> = ({
	title,
	text,
	setDeleteText,
	imageName,
	buttonNames,
	buttonEvents,
	onDelete,
	id,
	onClickToCard,
	loading,
	tickIcon
}) => {
	const [showDeleteBtn, setShowDeleteBtn] = useState(false);

	return (
		<div className='wrapper hover:scale-110 transition-all'>
			<div
				className='bucket-card h-full'
				onMouseEnter={() => setShowDeleteBtn(true)}
				onMouseLeave={() => setShowDeleteBtn(false)}
			>
				{showDeleteBtn ? (
					<span className='absolute right-0'>
						<button
						    id="deleteBtn"
							className='btn bg-white bg-opacity-50 hover:!bg-red-200 !rounded-none'
							onClick={() => {
								if(setDeleteText!==undefined && title!==undefined) {
									setDeleteText(title)
								}
								onDelete();
							}}
						>
							X
						</button>
					</span>
				) : (
					''
				)}
				{typeof imageName === 'string' ? (
					<img
						onClick={() => onClickToCard()}
						alt='folderCard'
						src={imageName}
						
						className='card__img object-list-card__img !p-2 h-full w-full'
					/>
				) : (
					<div className='loading-wrapper'>
						
					</div>
				)}

				<div className='bg-white flex p-2 items-center h-full'>
					{title ? (
						<div className='card__title object-list-card__title'>{title}</div>
					) : (
						''
					)}
					{text ? (
						<div className='object-card__description object-list-card__description'>
							{text}
						</div>
					) : (
						''
					)}

					{buttonNames.length === 1 ? (
						<Button
							variant=''
							className='card__btn'
							onClick={buttonEvents[0]}
						>
							{buttonNames[0]}
						</Button>
					) : (
						<ButtonGroup className='me-2'>
							{buttonNames.map((buttonName, i) => (
								<Button
									variant={buttonColors[i]}
									className='card__btn'
									onClick={buttonEvents[i]}
								>
									{buttonName}
								</Button>
							))}
						</ButtonGroup>
					)}
					{tickIcon && (
						<div className='tick-icon'>
							{tickIcon}
						</div>
					)}
				</div>

			</div>
		</div>
	);
};

export default CardItem;
