import React from 'react';
import '../../styles/onedrive/multipleUploadModel.css'
import { Button, Form, Modal } from 'react-bootstrap';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { LinearWithValueLabel } from '../LinearProgressBar';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { baseUrl } from '../../config/axios.config';
import LittleCircleLoading from '../Loading/LittleCircleLoading';
import { GetFolderItemModel } from '../../models/oneDrive/folder/get.folder.item.model';
import { AWSData } from '../../pages/aws/AWSUpload';
import { uploadAWSFilesToForge } from '../../store/features/aws/upload.aws.files.forge.slice';
import { fetchAWSFolder } from '../../store/features/aws/get.aws.folder.slice';
import { GetIAMFilesModelResponse } from '../../models/oneDrive/file/get.iam.files.model';
import CircleLoading from '../Loading/CircleLoading';
import { CheckManifestAWSService } from '../../services/aws/check.manifest.aws.service';
import { UploadSvfAWSService } from '../../services/aws/upload.svf.aws.service';
import { SelectableZipFiles } from '../../pages/object/ObjectList';
import { Box, Checkbox, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { InsertDriveFile } from '@mui/icons-material';


interface AddedFileListProps {
    file: string;
    bucket: string | undefined
    name: string;
    handleRemoveClick: () => void;
    fileNames: string[]
}

interface MultipleAWSUploadModalProps {
    show: boolean;
    onHide: () => void;
    fileList: AWSData[];
    setFileList: React.Dispatch<React.SetStateAction<AWSData[]>>;
    bucket: string | undefined;
    iamFileList:GetIAMFilesModelResponse|undefined
    zipOption: boolean
    setOpenZipOption: React.Dispatch<React.SetStateAction<boolean>>;
    zipFile: SelectableZipFiles | undefined;
    setZipFile: React.Dispatch<React.SetStateAction<SelectableZipFiles | undefined>>;
}

const AddedFileList: React.FC<AddedFileListProps> = ({
    file,
    bucket,
    name,
    handleRemoveClick,
    fileNames
}) => {
    const [isPrompt, setIsPrompt] = useState(false);
	const [folder, setFolder] = useState({
		name: "",
		check: false
	})

    const dispatch = useAppDispatch();
    const [isContinueProgress, setIsContinueProgress] = useState(false);
    const folderSelector = useAppSelector((x) => x.getAWSfolderslice);
  

    useEffect(() => {
        if (bucket && file) {
            const existFolderName = `${bucket}-${file}`;
            dispatch(fetchAWSFolder({
                bucketName:bucket,
                folderName:existFolderName
            }));
        }
    }, [bucket, file, dispatch]);

    useEffect(() => {
        if (
            folderSelector &&
            folderSelector.data &&
            folderSelector.data.folder &&
            !folderSelector.error &&
            !folderSelector.loading
        ) {
            const folder = folderSelector.data.folder as GetFolderItemModel;
            if (folder && folder.name) {
                setFolder({
                    ...folder,
                    check: false
                });
                setIsPrompt(true);
            }
        }
    }, [folderSelector]);

    return (
        <>
            {name && file && (
                <div className="flex items-center bg-gray-100 rounded-lg p-3 mb-2 shadow-sm hover:shadow-md transition-shadow duration-300">
                    <svg className="w-5 h-5 text-gray-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                    </svg>
                    <span  className="text-gray-700 font-medium truncate">{file}</span>
                </div>
            )}
        </>
    );
};

export const MultipleAWSUploadModal: React.FC<MultipleAWSUploadModalProps> = ({
    show,
    onHide,
    fileList,
    setFileList,
    bucket,
    iamFileList,
    setOpenZipOption,
    zipOption,
    setZipFile,
    zipFile,
}) => {
    const dispatch = useAppDispatch();
    const uploadIndexRef = useRef(0);

    const uploadAWSFilesSelector = useAppSelector(
        (s: RootState) => s.uploadAWSFilesToForgeSlice
    );
    const [progress, setProgress] = useState(0);
    const [step, setStep] = useState("");
    const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
    const [isConnected, setIsConnected] = useState(false);
    const fetchIAMFilesSelector=useAppSelector((s: RootState) =>s.getAWSIAMFilesSlice)
    const [selectedFile, setSelectedFile] = useState({
        name: "",
        size: ""
    });
    const [rootFile,setRootFile] = useState("")
    const [progressText, setProgressText] = useState("")
    const [isContinueProgress, setIsContinueProgress] = useState(false)
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(`${baseUrl}progress`)
            .withAutomaticReconnect()
            .build();

        setConnection(connection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.on('ReceiveProgress', (progressValue, stepValue) => {
                console.log(progressValue)
                console.log(stepValue)
                setProgress(progressValue || 0);
                setStep(stepValue || '');
            });

            return () => {
                if (isConnected) {
                    connection
                        .stop()
                        .then(() => {
                            console.log("Connection successfully stopped.")
                        });
                }
            };
        }
    }, [connection, isConnected]);

    const checkManifestStatus = async (
        urn: string,
        fileName: string,
        uid: string,
    ) => {
        const manifestService = new CheckManifestAWSService()
        let isRequestInProgress = false;
        let requestTimeoutId: NodeJS.Timeout | null = null;
        const REQUEST_TIMEOUT = 60000; // 60 saniye
        const INTERVAL_DELAY = 10000; // 10 saniye

        const intervalId = setInterval(async () => {
            // Gerekli veriler var mı kontrol et
            if (!urn || !uid) {
                console.log("Gerekli veriler mevcut değil.");
                clearInterval(intervalId); 
                return;
            }
            if (isRequestInProgress) {
                console.log("İstek zaten devam ediyor, bekleniyor...");
                return;
            }

            isRequestInProgress = true;
          
            requestTimeoutId = setTimeout(() => {
                console.log("İstek 1 dakikadan uzun sürdü, yeni istek yapılabilir.");
                isRequestInProgress = false; // İstek durumunu sıfırla, yeni isteklere izin ver
            }, REQUEST_TIMEOUT); // 60 saniye timeout

            try {
                console.log("response öncesi")
                const response = await manifestService.GetByStringManyParamsAsync([urn, fileName, uid]);

                console.log(response.progress);
                setIsContinueProgress(true);
                setProgressText(response.progress)
                if (response.progress === "complete") {
                    setProgressText("Finished.")  
                    clearInterval(intervalId);
                    if (requestTimeoutId) clearTimeout(requestTimeoutId);
                    console.log("İşlem tamamlandı ve interval durduruldu.");
                    return;
                } else {
                    setProgressText(response.progress + "...")
                }
            } catch (error) {
                console.error("Bir hata oluştu:", error);
            } finally {
                isRequestInProgress = false;

                if (requestTimeoutId) {
                    clearTimeout(requestTimeoutId);
                    requestTimeoutId = null;
                }
            }
        }, INTERVAL_DELAY);
    };


    const uploadFilesSequentially = (fileNames: string[], bucketKey: string,rootFileNames:string[]) => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    console.log('Connection successfully established.');
                    setIsConnected(true);

                    const uploadNextFile = () => {
                        setIsContinueProgress(true);
                        if (uploadIndexRef.current < fileNames.length) {
                            const fileName = fileNames[uploadIndexRef.current];
                            dispatch(
                                uploadAWSFilesToForge({
                                    fileNames:[fileName],
                                    bucketKey,
                                    rootFileNames
                                })
                            ).then(() => {
                                // Yükleme tamamlandı, bir sonraki dosyayı yükle
                                uploadIndexRef.current++;
                                uploadNextFile();
                            });
                        }
                    };

                    // İlk dosyayı başlat
                    uploadNextFile();
                })
                .catch((err) =>
                    console.log('Error while establishing the connection: ', err)
                );
        }
    };

    useEffect(() => {
        const { loading: uploadLoading, error: uploadError, data: uploadData } = uploadAWSFilesSelector;

        if (!uploadLoading && uploadData && uploadData.isSuccess) {
            // Dosya başarıyla yüklendiğinde buraya girecek
            if (uploadIndexRef.current === fileList.length - 1) {
                console.log("All files uploaded successfully!");
                checkManifestStatus(uploadData.urn, uploadData.fileName, uploadData.uid)
            } else {
                // Bir sonraki dosyayı yükle
                uploadFilesSequentially(fileList.map((file) => file.name), bucket as string,[rootFile]);
            }
        } else if (!uploadLoading && uploadError) {
            // Hata durumunda
            console.error("Error uploading file:", uploadError);
            // İşlemi durdur veya gerekli adımları atla
        }
    }, [uploadAWSFilesSelector, fileList, bucket, onHide, setFileList]);


    useEffect(() => {
        if (progressText === "Finished.") {
            setProgressText("Translating to SVF...")
            const uploadSvfFileService = new UploadSvfAWSService()
            uploadSvfFileService.UploadSvfWithFormDataAsync({
                bucketName: bucket as string,
                urn: uploadAWSFilesSelector.data ? uploadAWSFilesSelector.data.urn : "",
                userId: uploadAWSFilesSelector.data ? uploadAWSFilesSelector.data.uid : "",
                mainFileName: uploadAWSFilesSelector.data ? uploadAWSFilesSelector.data.fileName : ""
            }).then((response) => {
                console.log(response)
                setProgressText("")
                setIsContinueProgress(false)
                window.location.reload();
                onHide();
            })

        }
    }, [progressText])

    
    const handleSelectFileAsRoot = (name: string, size: number, fileName: string, id: string) => {
        setFileList((prev) => {
            return [...prev, {
                name: fileName,
                size,
                id: id,
                rootFileName: name
            }]
        })

        setOpenZipOption(false)
    }

    const handleselectedFile: any = (file: any) => {
        console.log(file)
        setSelectedFile(file)
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                if(uploadAWSFilesSelector.loading===false &&
                    uploadAWSFilesSelector.data!==null && uploadAWSFilesSelector.error===null){
                       window.location.reload();
                    }
                    else{
                        onHide();
                    }
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    fetchIAMFilesSelector.loading  ? (
                        <div className="text-center">
                            <CircleLoading text='Extracting all files from zip'/>
                        </div>
                    ):(
                        <Form>         
                    <Form.Group>
                        {
                            iamFileList!==undefined && iamFileList.iamFiles.length>0 && rootFile===""?(
                                <Box className="bg-white p-6 min-w-[400px] max-w-2xl mx-auto-overflow-y-hidden">
                                            <Box className="flex items-center justify-between mb-4">
                                                <Typography variant="h5" component="h2" className="font-semibold text-gray-800">
                                                    Selected files
                                                </Typography>

                                            </Box>
                                            <Box className="mb-4">
                                                <Typography variant="subtitle1" className="font-medium text-gray-700">
                                                    Main file{!selectedFile.name && <span className='text-xs text-red-400'>{" ("}Please select the main file.{")"}</span>}
                                                </Typography>
                                                {selectedFile.name && <Box className="flex items-center justify-between p-2 bg-gray-50 rounded">
                                                    <Box className="flex items-center">
                                                        <Checkbox
                                                            edge="start"
                                                            checked={true}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            className="text-blue-500"
                                                        />
                                                        <InsertDriveFile className="text-gray-400 mr-2" />
                                                        <Typography variant="body2">{selectedFile.name.split("/")[selectedFile.name.split("/").length - 1]}</Typography>
                                                    </Box>
                                                    <Box className="flex items-center">
                                                        <Typography variant="body2" className="text-gray-500 mr-2">
                                                            {selectedFile.size}
                                                        </Typography>
                                                    </Box>
                                                </Box>}
                                            </Box>
                                            <Box className="mb-4">
                                                <Typography variant="subtitle1" className="font-medium text-gray-700 mb-2">
                                                    Referans dosyalar {iamFileList.iamFiles.length}
                                                </Typography>
                                                <List className="divide-y divide-gray-200 max-h-[50vh] overflow-auto">
                                                    {iamFileList.iamFiles.map((file, i) => (
                                                        selectedFile.name != file.name &&
                                                        <ListItem
                                                            key={i}
                                                            dense
                                                            button
                                                            onClick={() => handleselectedFile(file)}
                                                            className="py-1"
                                                        >
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    tabIndex={-1}
                                                                    disableRipple

                                                                    checked={selectedFile.name == file.name}
                                                                    className="text-blue-500"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemIcon>
                                                                <InsertDriveFile className="text-gray-400" />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={file.name.split('/')[file.name.split('/').length - 1]}
                                                                className="text-sm"
                                                            />
                                                            <Typography variant="body2" className="text-gray-500">
                                                                {file.size}
                                                            </Typography>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Box>
                                            <Box className="flex flex-col items-center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={selectedFile?.name?.length < 1}
                                                    className="w-full max-w-xs border  hover:!bg-gray-200"
                                                    onClick={() => {
                                                        const splitList = selectedFile?.name?.split("/")
                                                        const newRootFileName = splitList[splitList?.length - 1]
                                                        setRootFile(newRootFileName)
                                                        setOpenZipOption(false)
                                                        setIsVisible(true);

                                                        console.log(selectedFile)
                                                    }}
                                                >
                                                    CONTINUE
                                                </Button>
                                            </Box>
                                        </Box>
                            ):(
                                <>
                                {fileList.map((file) => (
                                    <AddedFileList
                                        key={file.name}
                                        fileNames={fileList.map(file => file.name)}
                                        file={file.name || ""}
                                        handleRemoveClick={() => {
                                            const index = fileList.indexOf(file);
                                            if (index > -1) {
                                                if (index === 0) {
                                                    setFileList([]);
                                                } else {
                                                    setFileList((prev) => {
                                                        prev = prev.splice(index, 1);
                                                        return prev;
                                                    });
                                                }
                                            }
                                        }}
                                        name={file.name || ""}
                                        bucket={bucket}
                                    />
                                ))}
                              </>
                            
                            )
                           
                        }
                      </Form.Group>
                </Form>
                    )
                }
                
            </Modal.Body>
            <Modal.Footer>
                {uploadAWSFilesSelector.data && !uploadAWSFilesSelector.loading &&
                    !uploadAWSFilesSelector.error &&
                    uploadAWSFilesSelector.data.isSuccess && progress === 100 && (
                        <>
                            {fileList.length > 1 ? (
                                <p style={{ color: "green", marginRight: "auto" }}>
                                    Files have been uploaded succesfully
                                </p>
                            ) : (
                                    <p style={{ color: "green", marginRight: "auto" }}>
                                        File has been uploaded successfully
                                    </p>
                                )}
                        </>
                    )}
                {((bucket && isVisible) || (!iamFileList)) && !isContinueProgress && !fetchIAMFilesSelector.loading &&(
                    <Button
                        className='uploadButton'
                        disabled={uploadAWSFilesSelector.loading || fileList.length === 0}
                        variant={uploadAWSFilesSelector.loading ? "secondary" : "primary"}
                        hidden={progress === 100 && !isContinueProgress}
                        onClick={() => {
                            uploadFilesSequentially(fileList.map((file) => file.name), bucket as string,[rootFile]);
                        }}
                    >
                        Translate {fileList.length > 1 ? "Files" : "File"}
                    </Button>
                )}

                {           
                    isContinueProgress &&
                    <div className="flex items-center w-full justify-center py-4 bg-blue-50 rounded-lg shadow-sm">
                        <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span className="text-blue-700 font-medium">
                            {progressText ? progressText : "Translation process is being initiated..."}
                        </span>
                    </div>
                }
            </Modal.Footer>
        </Modal>
    );
};

export default MultipleAWSUploadModal;


