import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { SubscriberModel, SubscriberResponseModel } from "../../../models/subscriber/subscriber.model";
import { PutSubscriberService } from "../../../services/subscriber/put.subscriber.service";





interface SubscriberState extends BaseState<SubscriberResponseModel>{

}

let initialState:SubscriberState={
    data:null,
    loading:false,
    error:null
}

export const fetchPutSubscriber=createAsyncThunk("fetch/PutSubscriber",
async(request:SubscriberModel,thunkAPI)=>{

    try{
        const service=new PutSubscriberService()
        const response=await service.SubscribeAsync(request) 
        return response
    }
    catch(err:any){
        if (!err.response) {
            throw err
          }
          const errorData=err.response.data
          
          return thunkAPI.rejectWithValue({
            error: errorData.message
          })
    }
})


export const SubscriberSlice=createSlice({
    name:"SubscriberSlice",
    initialState,
    reducers:{
        resetSubscriberState:(state)=>{
            state.data=null;
            state.loading=false;
            state.error=null;
        }
    },
    extraReducers(builder) {
        builder
        .addCase(fetchPutSubscriber.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchPutSubscriber.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchPutSubscriber.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})


export const { resetSubscriberState} = SubscriberSlice.actions

export default SubscriberSlice.reducer