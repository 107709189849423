import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BaseState } from '../../../commons/base.state';
import { LoginRequest, LoginResponse } from '../../../models/auth/login.model';
import { LoginPostService } from '../../../services/auth/login.post.service';
import { AdminLoginRequestModel, AdminLoginResponseModel } from '../../../models/admin/admin.login.model';
import { AdminLoginService } from '../../../services/admin/admin.login.service';

interface AdminAuthLoginState extends BaseState<AdminLoginResponseModel> { }

let initialState: AdminAuthLoginState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchAdminLogin = createAsyncThunk(
  'fetch/adminLogin',
  async (request: AdminLoginRequestModel, thunkAPI) => {
    try {
      const service = new AdminLoginService()
      const response = await service.PostAsync({
        userName: request.userName,
        password: request.password,
      });
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      const errorData = err.response.data;
      return thunkAPI.rejectWithValue({
        error: errorData.error ? errorData.error : errorData.message,
      });
    }
  }
);

export const adminLoginSlice = createSlice({
  name: 'adminLoginSlice',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAdminLogin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchAdminLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAdminLogin.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetError } = adminLoginSlice.actions;

export default adminLoginSlice.reducer;
