import React from 'react';

const FileFormats: React.FC = () => {
  const formats = [ "ZIP",
    "3DM", "FSCH", "PSM",
    "3DS", "G", "PSMODEL",
    "A", "GBXML", "RVM",
    "ASM", "GLB", "RVT **",
    "AXM", "GLTF", "SAB",
    "BRD", "IAM", "SAT",
    "CATPART", "IDW", "SCH",
    "CATPRODUCT", "IFC", "SESSION",
    "CGR", "IGE", "SKP",
    "COLLABORATION", "IGES", "SLDASM",
    "DAE", "IGS", "SLDPRT",
    "DDX", "IPT", "SMB",
    "DDZ", "IWM", "SMT",
    "DGK", "JT", "STE",
    "DGN", "MAX", "STEP",
    "DLV3", "MODEL", "STL",
    "DMT", "MPF", "STLA",
    "DWF", "MSR", "STLB",
    "DWFX", "NEU", "STP",
    "DWG *", "NWC", "STPZ",
    "DWT", "NWD", "VPB",
    "DXF", "OBJ", "VUE",
    "EMODEL", "OSB", "WIRE",
    "EXP", "PAR", "X_B",
    "F3D", "PMLPRJ", "X_T",
    "FBRD", "PMLPRJZ", "XAS",
    "FBX", "PRT", "XPR"
  ];

  return (
    <div className="bg-white rounded-lg max-w-2xl mx-auto p-6 overflow-hidden xs:w-full xs:p-0 xs:!m-0 ">
      <div className="flex justify-between items-center mb-4 pb-2 border-b">
        <h2 className="text-lg font-semibold text-gray-800">Supported 2D and 3D file formats</h2>
        <button className="text-gray-500 hover:text-gray-700"></button>
      </div>
      <p className="mb-4 text-sm text-gray-600">Veewer can display the following file formats:</p>
      <div className="grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 gap-x-4 gap-y-2 text-xs xs:grid-cols-3 xs:gap-x-2 w-full overflow-hidden">
        {formats.map((format, index) => (
          <div key={index} className="text-gray-700 w-fit">{format}</div>
        ))}
      </div>
    </div>
  );
};

export default FileFormats;
