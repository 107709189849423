"use client"

import React, { useState, useEffect, useRef } from 'react'
import EngineMKII from "../assets/engine.png"
import Stapler from "../assets/Stapler.png"
import Blower from "../assets/Blower.png"
import Suspension from "../assets/Suspension.png"
import { baseUrl } from '../config/axios.config'

export default function Examples() {
  const [showAnimation, setShowAnimation] = useState(false)
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [iframesLoaded, setIframesLoaded] = useState<boolean[]>([])
  const [firstIframeLoaded, setFirstIframeLoaded] = useState(false)
  const iframeRefs = useRef<(HTMLIFrameElement | null)[]>([])

  const images = [{
    src: EngineMKII,
    title: "Engine MKII",
    url: `${baseUrl}sample/models/Engine MKII.iam`,
    iframe: `${baseUrl}sample/models/Engine MKII.iam`,
    description: 'This 3D model of a engine can be rotated and zoomed in 360 degrees, allowing you to explore every part and mechanism. Ideal for training and understanding complex tool designs.'
  }, {
    src: Stapler,
    title: "Stapler",
    url: `${baseUrl}sample/models/Stapler.iam`,
    iframe: `${baseUrl}sample/models/Stapler.iam`,
    description: 'Experience a full 360-degree view of a stapler in action. Zoom in to see the detailed components and how they work together to improve office productivity.'
  }, {
    src: Blower,
    title: "Blower",
    url: `${baseUrl}sample/models/Blower.iam`,
    iframe: `${baseUrl}sample/models/Blower.iam`,
    description : 'Control and rotate this blower’s 3D model to see how each component works. Examine airflow paths and get a close-up view of the internal mechanisms for a better understanding of its operation.'
  }, {
    src: Suspension,
    title: "Suspension",
    url: `${baseUrl}sample/models/Suspension.iam`,
    iframe: `${baseUrl}sample/models/Suspension.iam`,
    description: 'Explore this 3D suspension model from any angle. Rotate it to view all the components, from springs to dampers, and see how each part contributes to a smooth ride.'
  }]

  useEffect(() => {
    setShowAnimation(true)
    const timer = setTimeout(() => {
      setShowAnimation(false)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    setIframesLoaded(new Array(images.length).fill(false))
  }, [images.length])

  const handleIframeLoad = (index: number) => {
    setIframesLoaded(prev => {
      const newState = [...prev]
      newState[index] = true
      return newState
    })
  }

  const handleFirstIframeLoad = () => {
    setFirstIframeLoaded(true)
  }

  return (
    <div className=" max-w-screen-xl mx-auto p-4 md:px-4 md:py-16 mt-24">
      <h2 className="text-clamp font-extrabold mb-6 text-center text-gray-800 tracking-tight">
        Inspiring Examples
      </h2>
      <p className="mb-12 text-center text-gray-600 max-w-2xl mx-auto leading-relaxed text-lg">
      Explore our selection of interactive 3D models that allow users to fully engage with each design. Rotate, zoom, and explore every detail to gain a deeper understanding of how these models can benefit your projects.   </p> 

      <iframe 
        id='first' 
        className='hidden' 
        width={0} 
        height={0} 
        src={`${baseUrl}sample/models/Wrench.iam`}
        title='3d-model'
        onLoad={handleFirstIframeLoad}
      />
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        {images.map((image, index) => (
          <div 
            key={index} 
            className="group transition-all duration-300 hover:-translate-y-2"
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <div className="relative overflow-hidden  shadow-lg transition-all duration-300 group-hover:shadow-2xl bg-black h-64 rounded-lg ">
              <img
                src={image.src}
                alt={`Example ${index + 1}`}
                onMouseMove={ () => setShowAnimation(false)}
                className={`w-full h-full object-cover transition-opacity duration-300 rounded-lg  ${
                  hoveredIndex === index ? 'opacity-0' : 'opacity-100'
                } ${index === 0 && showAnimation ? 'blur-sm' : ''}`}
              />
              <iframe
                ref={el => iframeRefs.current[index] = el}
                src={image.iframe}
                title={image.title}
                className={`absolute inset-0 w-full h-full rounded-lg ${
                  hoveredIndex === index 
                    ? iframesLoaded[index]
                      ? 'opacity-100'
                      : 'opacity-0 transition-opacity duration-700'
                    : 'opacity-0 transition-opacity duration-300'
                }`}
                frameBorder="0"
                allowFullScreen
                onLoad={() => handleIframeLoad(index)}
              ></iframe>
              {hoveredIndex === index && !iframesLoaded[index] && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                  <svg className="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              )}
              {index === 0 && showAnimation && (
                <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50">
                  <div className="animate-bounce mb-2">
                    <svg className="w-12 h-12 text-white" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"></path>
                    </svg>
                  </div>
                  <p className="text-white text-lg font-semibold">Show on Veewer</p>
                </div>
              )}
            </div>
            <div className='py-4'>
              <div className='flex justify-between'> 
                <h3 className="text-lg font-semibold text-gray-800 mb-2">{image.title}</h3>
                <a href={image.url} className='underline' target='_blank'>Open new tab</a>
              </div>
              <p className="text-sm text-gray-600">{image.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}