import { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { Col, Container, Modal } from 'react-bootstrap';
import CardItem from '../../components/CardItem';
import CircleLoading from '../../components/Loading/CircleLoading';
import IAM from '../../assets/IAM.png';
import IPT from '../../assets/IPT.png';
import DWFX from '../../assets/DWFX.png';
import ZIP from '../../assets/zip.svg';
import RVT from '../../assets/RVT.jpg'
import DWG from '../../assets/DWG.jpg'
import IDW from '../../assets/IDW.png'
import circleLoadingGif from '../../assets/circleLoading.gif'
import http, { baseUrl } from '../../config/axios.config';
import SecondHeader from '../../components/Navbar/SecondHeader';
import TextAreaModal from '../../components/Modals/TextAreaModal';
import '../../styles/onedrive/oneDrive.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlushed } from '@fortawesome/free-solid-svg-icons';
import { fetchAllAWSFolders } from '../../store/features/aws/get.all.aws.folders.slice';

const AWSFolderList = () => {
	const dispatch = useAppDispatch();
	const folderListSelector = useAppSelector((s: RootState) => s.getAllAWSFoldersSlice);
	const folderList = folderListSelector.data
    const bucketKey=sessionStorage.getItem('b') as string

	const [htmlContent, setHtmlContent] = useState("")
	const [htmlAreaModal, setHtmlAreaModal] = useState(false)

	const [loading,setLoading]=useState({
		loading:false,
		itemName:""
	})
	const [generateLoading,setGenerateLoading]=useState({
		loading:false,
		itemName:""
	})

	useEffect(() => {
		dispatch(fetchAllAWSFolders(bucketKey));
	}, [dispatch]);

	return  (
			<>

				{folderListSelector.loading ? (
					 <div className='loading-bucket'>
					 <CircleLoading text='Trying to get translated AWS files...' />
				   </div>
				) : (
					<div>
						{folderList?.folders && folderList.folders.length > 0 ? (
						  <div className='layout-grid-folder-list'>
						{folderList.folders.map((folder) => (
							<Col>
								<CardItem
									imageName={
										loading.itemName===folder.name && loading.loading?circleLoadingGif:(folder.thumbnail !== "" ?
											`data:image/jpeg;base64,${folder.thumbnail}` :folder.name.includes('iam')?IAM:
											 folder.name.includes('ipt')?IPT:
											 folder.name.includes('dwfx')?DWFX:
											 folder.name.includes('zip')?ZIP:
											 folder.name.includes('rvt')?RVT:
											 folder.name.includes('dwg')?DWG:
											 folder.name.includes('idw')?IDW:
											 folder.name.includes('dwfx')?DWFX:'')
									}
									title={folder.name}
									buttonNames={[generateLoading.itemName===folder.name && generateLoading.loading?"Generating to embed code...":"</>"]}
									buttonEvents={[() => {
										const request = http(
											'box',
											`files/view/add/${folder.name}`
										);

										setGenerateLoading({
											loading:true,
											itemName:folder.name
										})
										
										request.get('').then(() => {
											const viewUrl = `${baseUrl}aws/files/view/get/${bucketKey}/index.html`
											setGenerateLoading({
												loading:false,
												itemName:""
											})
											setHtmlContent(
												   `<iframe frameborder='0' width='500px' height='500px' allowfullscreen mozallowfullscreen='true' webkitallowfullscreen='true' 
				                                    fullscreen; xr-spatial-tracking xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share
												    src='${viewUrl}'></iframe>`)
											setHtmlAreaModal(true)
										})
									}]}
									onClickToCard={async () => {
										const request = http(
											'aws',
											`files/view/add/${folder.name}`
										);
										setLoading({
											loading:true,
											itemName:folder.name
										})
										await request.get('').then(() => {
											setLoading({
												loading:false,
												itemName:""
											})
											window.open(
												`${baseUrl}aws/files/view/get/${bucketKey}/index.html`
											);
										});
									}}
									onDelete={() => { }}
								/>
							</Col>
						))}
						{
							htmlAreaModal === true ? (
								<TextAreaModal
									show={htmlAreaModal}
									onHide={setHtmlAreaModal}
									incomingValue={htmlContent}
									secondOptionalValue='' />
							) : (<></>)
						}
					</div>
				) :(<Container
					className='modal show'
					style={{
						display: 'block',
						position: 'initial',
						alignContent: 'center',
						marginTop: '200px',
					}}
				>
					<Modal.Dialog>
						<Modal.Header>
							<Modal.Title>
								<FontAwesomeIcon icon={faFlushed} />
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							There is no translated AWS file here
						</Modal.Body>
					</Modal.Dialog>
				</Container>)}
			</div >)}
			</>)
};

export default AWSFolderList;