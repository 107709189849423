import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TokenRefreshService } from '../services/auth/token.refresh.service';
import { LoginResponse } from '../models/auth/login.model';

const TokenRenewalHandler = () => {
  const location = useLocation();

  const refreshToken = async (uid: string) => {
    try {
      const refreshTokenService = new TokenRefreshService();
      const response = await refreshTokenService.GetByStringParamAsync(uid);
      return response
    } catch (error) {
      console.error('Token yenilenirken hata oluştu:', error);
    }
  };

  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem('login');
    if (isLoggedIn !== null) {
      const defaultLoginResponse = JSON.parse(isLoggedIn) as LoginResponse;
        try {
            refreshToken(defaultLoginResponse.uid).then((response) => {
              const data = JSON.stringify({
                isAuthenticated: response ? response.isAuthenticated : false,
                expiresAt: response ? response.expiresAt : "",
                isAdmin: defaultLoginResponse.isAdmin,
                isUser: defaultLoginResponse.isUser,
                isOwner: defaultLoginResponse.isOwner,
                uid: defaultLoginResponse.uid,
              });
              sessionStorage.setItem('login', data);
            }).catch((error) => {
              console.log(error);
            });

        } catch (error) {
          console.error("Error processing login time:", error);
        }
    }
  }, [location]);

  return null;
};

export default TokenRenewalHandler;
