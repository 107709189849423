import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UpdateModelIframeCSSButtonRequestModel, UpdateModelIframeCSSButtonResponseModel } from "../../../models/object/uploadedObject/update.iframeCssButton.model";
import { UpdateModelIframeCSSButtonService } from "../../../services/uploadedObject/update.iframeCSSButton.service";





interface UpdateIframeCSSButtonState extends BaseState<UpdateModelIframeCSSButtonResponseModel>{

}


let initialState:UpdateIframeCSSButtonState={
    data:null,
    error:null,
    loading:false
}


export const updateIframeCSSCode=createAsyncThunk("uploadedObject/updateIframeCSSCode",
    async(request:UpdateModelIframeCSSButtonRequestModel)=>{
    
        const service=new UpdateModelIframeCSSButtonService()
        const response=await service.PostAsync(request)  
        return response
})



export const updateIframeCSSCodeSlice=createSlice({
    name:"updateIframeCSSCodeSlice",
    initialState,
    reducers:{
        resetCreateState:(state)=>{
            state.data = null;
            state.loading = false;
            state.error = null;
        }
    },
    extraReducers(builder){
        builder
        .addCase(updateIframeCSSCode.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(updateIframeCSSCode.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(updateIframeCSSCode.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
        
    }
       
})

export const { resetCreateState } = updateIframeCSSCodeSlice.actions;
export default updateIframeCSSCodeSlice.reducer