import { forwardRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import '../styles/home/register.css';
import { fetchBetaUser, resetBetaUserState } from '../store/features/betaUser/betaUser.slice';
import LittleCircleLoading from './Loading/LittleCircleLoading';

// Register component using forwardRef
const Register = forwardRef<HTMLDivElement>((props, ref) => {

    const betaUser = useAppSelector(s => s.betaUserSlice);
    const dispatch = useAppDispatch();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');

    return (
        <section ref={ref} className='register-section'>
            <div className='register-content'>
                <h2 className='register-title'>Join Our Beta Program!</h2>
                <p className='register-desc'>
                    Be the first to experience VEEWER and help shape its future
                </p>
                <form className='register-form'>
                    <div className='register-form-container'>
                        <div className='form-group'>
                            <input
                                required
                                type='text'
                                placeholder='First name *'
                                value={firstName}
                                onChange={(e) => {
                                    setFirstName(e.target.value);
                                }}
                            />
                        </div>
                        <div className='form-group'>
                            <input
                                required
                                type='text'
                                placeholder='Last name *'
                                value={lastName}
                                onChange={(e) => {
                                    setLastName(e.target.value);
                                }}
                            />
                        </div>
                        <div className='form-group'>
                            <input
                                required
                                type='email'
                                placeholder='Email *'
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </div>
                        <div className='form-group'>
                            <input
                                required
                                type='text'
                                placeholder='Company name *'
                                value={companyName}
                                onChange={(e) => {
                                    setCompanyName(e.target.value);
                                }}
                            />
                        </div>
                        <button
                            type="button"
                            className='register-submit-btn'
                            disabled={betaUser.loading ? true : false}
                            onClick={() => {
                                dispatch(fetchBetaUser({
                                    firstName,
                                    lastName,
                                    companyName,
                                    email
                                })).then(()=>{
                                    dispatch(resetBetaUserState())
                                })
                            }}>
                            {betaUser.loading ? <LittleCircleLoading /> : 'Register'}
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
});



export default Register;
