

import { CheckCreditWhenUploadResponseModel } from "../../models/object/uploadedObject/check.credit.model";
import { BaseGetService } from "../base/base.get.service";


export class CheckCreditWhenUploadService extends BaseGetService<CheckCreditWhenUploadResponseModel>{
    constructor(controllerName:string="objects",endpoint:string="creditStatus"){
        super(controllerName,endpoint)
    }
}