import { Button, ButtonGroup, Modal } from "react-bootstrap"
import CircleLoading from "../Loading/CircleLoading"




interface ConfirmModalProps{
    onYes:()=>void,
    onNo:()=>void,
    show:boolean,
    onHide:(val:boolean)=>void
    word:string
    ifYes:boolean

}


const ConfirmModal:React.FC<ConfirmModalProps>=({
    onYes,
    onNo,
    show,
    onHide,
    word,
    ifYes
})=>{
    return (
       <Modal closeButton show={show} onHide={()=>{
           onHide(false)
       }} >
        
        {
            ifYes ? (
                <CircleLoading text="Model is deleting..."/>
            ):(
                <>
                <Modal.Body>
                Are you sure to {word} ?
             </Modal.Body>
             <Modal.Footer>
                <ButtonGroup>
                    <Button variant='primary' onClick={()=>{onNo()}}>No</Button>
                    <Button variant='danger' onClick={()=>{
                        onYes()
                       
                        
                    }}>Yes</Button>
                </ButtonGroup>
             </Modal.Footer>
             </>
            )
        }
        
       </Modal>
    )
}


export default ConfirmModal