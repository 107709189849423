import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { SecondaryGetUploadedObjectRequest } from "../../../models/object/uploadedObject/get.uploadedObject.request.model";
import { GetSubUploadedObjectResponseModel, GetUploadedObjectResponseModel } from "../../../models/object/uploadedObject/get.uploadedObject.response.model";
import { GetListUploadedObjectService } from "../../../services/uploadedObject/get.list.uploadedObject.service";
import { SubscriptionPlan } from "../../../models/subscription/get.all.subscription.info.model";
import { GetAllSubscriptionInfosService } from "../../../services/subscription/get.all.subscription.infos.service";



interface SubscriptionListState extends BaseState<SubscriptionPlan[]>{

}


let initialState:SubscriptionListState={
    data:null,
    error:null,
    loading:false
}


export const getSubscriptions=createAsyncThunk("subscriptions/get",async()=>{
    
    const service=new GetAllSubscriptionInfosService()
    const response=await service.GetAsync()
    return response

})


export const getSubscriptionsSlice=createSlice({
    name:"getSubscriptionsSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(getSubscriptions.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(getSubscriptions.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(getSubscriptions.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })      
    }
})



export default getSubscriptionsSlice.reducer




