import { combineReducers } from '@reduxjs/toolkit'

import createBucket from './features/bucket/create.bucket.slice'
import deleteBucket from './features/bucket/delete.bucket.slice'
import getBucketList from './features/bucket/get.bucket.list.slice'
import getBucket from './features/bucket/get.bucket.slice'
import getUserBucket from './features/bucket/get.user.bucket.slice'
import getListUploadedObject from './features/object/get.list.uploadedObject.slice'
import getUploadedObject from './features/object/get.uploadedObject.slice'
import createUploadedObject from './features/object/create.uploadedObject.slice'
import getUploadedObjectHtmlContent from './features/object/get.uploadedObject.html.content.slice'
import getToken from './features/token/get.token.slice'
import postToken from './features/token/post.token.slice'
import getAuthorizationUrl from './features/auth/get.auth.url.slice'
import postAuth from './features/auth/auth.post.slice'
import getAllFiles from './features/oneDrive/get.all.files.slice'
import getFile from './features/oneDrive/get.file.slice'
import getFileListByFolder from './features/oneDrive/get.file.list.by.folder.slice'
import getFilesByTypeFilter from './features/oneDrive/get.file.types.files.slice'
import getFileViewUrl from './features/oneDrive/get.file.view.url.slice'
import getFolder from './features/oneDrive/get.folder.slice'
import getFolderList from './features/oneDrive/get.all.folders.slice'
import uploadOneDriveFilesToForge from './features/oneDrive/upload.files.to.forge.slice'
import uploadFilesToForgeSecondary from './features/oneDrive/upload.files.to.forge.secondary.slice'
import getAllUsersSlice from './features/user/get.all.users.slice'
import getUserAllFilesCountSlice from './features/dashboard/get.user.all.files.count.slice'
import getUserCreditCountSlice from './features/dashboard/get.user.credit.count.slice'
import fetchUserRegisterSlice from './features/user/user.register.slice'
import copyItemSlice from './features/oneDrive/copy.item.slice'
import getRecentFilesSlice from './features/oneDrive/get.recent.files.slice'
import uploadPrevFilesSlice from './features/oneDrive/upload.prev.files.slice'
import googleAuthSlice from './features/auth/google.get.token.slice'
import microsoftAuthSlice  from './features/auth/microsoft.get.token.slice'
import getAllGoogleDriveFilesSlice from './features/googleDrive/get.all.googleDrive.files.slice'
import uploadGoogleDrivePrevFilesSlice from './features/googleDrive/upload.prev.googleDrive.files.slice'
import uploadGoogleDriveFilesToForgeSlice from './features/googleDrive/upload.googleDrive.files.forge.slice'
import getGoogleDriveAllFoldersSlice from './features/googleDrive/get.googleDrive.all.folders.slice'
import getGoogleDriveFolderSlice from './features/googleDrive/get.googleDrive.folder.slice'
import postBoxTokenSlice from './features/auth/box.get.token.slice'
import getBoxFolderSlice from './features/box/get.box.folder.slice'
import getAllBoxFilesSlice from './features/box/get.all.box.files.slice'
import uploadBoxFilesForgeSlice from './features/box/upload.box.files.forge.slice'
import uploadPrevBoxFilesSlice from './features/box/upload.prev.box.files.slice'
import getAllBoxFoldersSlice from './features/box/get.all.box.folders.slice'
import getAWSfileslice from './features/aws/get.aws.file.slice'
import getAllAWSFilesSlice from './features/aws/get.all.aws.files.slice'
import getAWSfolderslice from './features/aws/get.aws.folder.slice'
import getAllAWSFoldersSlice from './features/aws/get.all.aws.folders.slice'
import uploadPrevAwsFilesSlice from './features/aws/upload.prev.aws.files.slice'
import uploadAWSFilesToForgeSlice from './features/aws/upload.aws.files.forge.slice'
import createAwsBucketSlice from './features/aws/create.aws.bucket.slice'
import postAWSTokenSlice  from './features/auth/aws.post.token.slice'
import deleteFolderSlice from './features/oneDrive/delete.folder.slice'
import userInfoSlice from './features/user/get.user.slice'
import createTeamUserSlice from './features/user/create.team.user.slice'
import deleteFileSlice from './features/oneDrive/delete.file.slice'
import getOwnerUsersSlice from './features/user/get.owner.users.slice'
import getAdminUsersSlice from './features/user/get.admin.users.slice'
import createAdminTeamUserSlice from './features/user/create.admin.team.user.slice'
import betaUserSlice from './features/betaUser/betaUser.slice'
import subscriberSlice from './features/subscriber/subscriber.slice'
import verifyPhoneSlice from './features/verifyPhone/verify.phone.slice'
import forgotPasswordSlice from './features/forgotPassword/forgot.password.slice'
import resetPasswordSlice from './features/resetPassword/reset.password.slice'
import deleteObjectSlice from './features/object/delete.object.slice'
import getIAMFilesSlice from './features/oneDrive/get.iam.files.service'
import getAWSIAMFilesSlice from './features/aws/get.iam.files.slice'
import getUploadedObjectMetadataSlice from './features/object/get.uploadedObject.metadata.slice'
import checkManifestUploadedObjectSlice from './features/object/check.manifest.uploadedObject.slice'
import checkCreditUploadedObjectSlice from './features/object/check.credit.slice'
import getBoxSubFilesSlice  from './features/box/get.sub.files.from.zip.slice'
import CheckUserWebSitesSlice from './features/user/check.user.websites.slice'
import UpdateUserWebSitesSlice from './features/user/update.user.websites.slice'
import UpdateIframeCSSCodeSlice from './features/object/update.iframeCSSButton.slice'
import checkCreditUploadedObjectWhenUploadSlice from './features/object/check.credit.when.upload.slice'
import  getUserListSlice  from './features/admin/get.userlist.slice'
import  adminLoginSlice  from './features/admin/admin.login.slice'
import getSubscriptionsSlice  from './features/subscription/get.all.subscription.infos.slice'




const rootReducer = combineReducers({
    getToken,
    postToken,
    getAuthorizationUrl,
    createBucket,
    deleteBucket,
    getBucketList,
    getBucket,
    getUserBucket,
    getUploadedObjectList:getListUploadedObject.getUploadedObjectsReducer,
    getUploadedObjectListBucketKey:getListUploadedObject.getUploadedObjectsBucketKeyReducer,
    getUploadedObject:getUploadedObject.getUploadedObject,
    getUploadedObjectByBucketAndName:getUploadedObject.getUploadedObjectByBucketAndName,
    createUploadedObject,
    getUploadedObjectHtmlContent,
    getAllFiles,
    getFile,
    getFilesByTypeFilter,
    getFileListByFolder,
    getFileViewUrl,
    getFolder,
    getFolderList,
    uploadOneDriveFilesToForge,
    uploadFilesToForgeSecondary,
    getAllUsersSlice,
    postAuth,
    fetchUserRegisterSlice,
    getUserAllFilesCountSlice,
    getUserCreditCountSlice,
    copyItemSlice,
    uploadPrevFilesSlice,
    getRecentFilesSlice,
    googleAuthSlice,
    microsoftAuthSlice,
    getAllGoogleDriveFilesSlice,
    uploadGoogleDrivePrevFilesSlice,
    uploadGoogleDriveFilesToForgeSlice,
    getGoogleDriveAllFoldersSlice,
    getGoogleDriveFolderSlice,
    postBoxTokenSlice,
    getBoxFolderSlice,
    getAllBoxFoldersSlice,
    getAllBoxFilesSlice,
    uploadBoxFilesForgeSlice,
    uploadPrevBoxFilesSlice,
    getAWSfileslice,
    getAllAWSFilesSlice,
    getAWSfolderslice,
    getAllAWSFoldersSlice,
    uploadPrevAwsFilesSlice,
    uploadAWSFilesToForgeSlice,
    createAwsBucketSlice,
    postAWSTokenSlice,
    deleteFolderSlice,
    userInfoSlice,
    getOwnerUsersSlice,
    createTeamUserSlice,
    deleteFileSlice,
    getAdminUsersSlice,
    createAdminTeamUserSlice,
    betaUserSlice,
    subscriberSlice,
    verifyPhoneSlice,
    forgotPasswordSlice,
    resetPasswordSlice,
    deleteObjectSlice,
    getIAMFilesSlice,
    getAWSIAMFilesSlice,
    getUploadedObjectMetadataSlice,
    checkManifestUploadedObjectSlice,
    checkCreditUploadedObjectSlice,
    getBoxSubFilesSlice,
    CheckUserWebSitesSlice,
    UpdateUserWebSitesSlice,
    UpdateIframeCSSCodeSlice,
    checkCreditUploadedObjectWhenUploadSlice,
    getUserListSlice,
    adminLoginSlice,
    getSubscriptionsSlice
    
})




export default rootReducer