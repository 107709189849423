import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { CheckUserWebSitesResponseModel } from "../../../models/user/check.user.websites.model";
import { CheckUserWebSitesService } from "../../../services/user/check.user.websites.service";

interface CheckUserWebSitesState extends BaseState<CheckUserWebSitesResponseModel> {}

const initialState: CheckUserWebSitesState = {
    data: null,
    loading: false,
    error: null,
};

export const checkUserWebSites = createAsyncThunk(
    "fetch/CheckUser",
    async (uid:string, thunkAPI) => {
        try {
            
            const service=new CheckUserWebSitesService()
            const response = await service.GetByStringParamAsync(uid)

            return response;
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }

            const errorData = err.response.data;

            return thunkAPI.rejectWithValue({
                error: errorData.error ? errorData.error : errorData.message,
            });
        }
    }
);

export const CheckUserWebSitesSlice = createSlice({
    name: "CheckUserWebSitesSlice",
    initialState,
    reducers: {
        reset: (state) => {
            return initialState; // Reset state to initial values
        },
    },
    extraReducers(builder) {
        builder
            .addCase(checkUserWebSites.pending, (state) => {
                state.loading = true;
            })
            .addCase(checkUserWebSites.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(checkUserWebSites.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { reset } = CheckUserWebSitesSlice.actions

export default CheckUserWebSitesSlice.reducer
