import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UpdateUserWebSitesRequestModel, UpdateUserWebSitesResponseModel } from "../../../models/user/update.user.websites.model";
import { UpdateUserWebSitesService } from "../../../services/user/update.user.websites.service";

interface UpdateUserWebSitesState extends BaseState<UpdateUserWebSitesResponseModel> {}

const initialState: UpdateUserWebSitesState = {
    data: null,
    loading: false,
    error: null,
};

export const fetchUpdateUserWebSites = createAsyncThunk(
    "fetch/UpdateUser",
    async (request: UpdateUserWebSitesRequestModel, thunkAPI) => {
        try {
            
            const service=new UpdateUserWebSitesService()
            const response = await service.PutAsyncWithData(request)

            return response;
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }

            const errorData = err.response.data;

            return thunkAPI.rejectWithValue({
                error: errorData.error ? errorData.error : errorData.message,
            });
        }
    }
);

export const UpdateUserWebSitesSlice = createSlice({
    name: "UpdateUserWebSitesSlice",
    initialState,
    reducers: {
        reset: (state) => {
            return initialState; // Reset state to initial values
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchUpdateUserWebSites.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchUpdateUserWebSites.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchUpdateUserWebSites.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { reset } = UpdateUserWebSitesSlice.actions

export default UpdateUserWebSitesSlice.reducer
