import { GetSubUploadedObjectMetadataResponseModel, GetUploadedObjectMetadataResponseModel } from "../../models/object/uploadedObject/get.uploadedObject.response.model";
import { BaseGetService } from "../base/base.get.service";




export class GetUploadedObjectMetadataService extends BaseGetService<GetSubUploadedObjectMetadataResponseModel>
{
    
}

