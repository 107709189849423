'use client'

import React, { useRef, useEffect, useState } from 'react'

export default function LoadingScreen({ textColor = 'black', text = "VEEWER", tailwindcss = "", loadingTime = "3s" }) {
  const textRef = useRef(null);
  const [textWidth, setTextWidth] = useState(0);

  useEffect(() => {
    // Metnin genişliğini alıyoruz
    if (textRef.current) {
      setTextWidth(textRef.current.offsetWidth);
    }
  }, []);

  return (
    <div className="flex justify-center items-center h-full w-full">
      <div className="relative text-center">
        <div
          ref={textRef} // Metin genişliğini almak için referans
          className={`text-7xl font-bold tracking-[0.5em] ${tailwindcss} `}
          style={{
            color: 'transparent',
            WebkitTextStroke: `2px ${textColor}`,
          }}
        >
          {text}
        </div>
        <div
          className={`absolute top-0 left-0 overflow-hidden whitespace-nowrap text-7xl font-bold tracking-[0.5em] ${tailwindcss}`}
          style={{
            color: textColor,
            WebkitTextStroke: `2px ${textColor}`,
            animation: `fillAnimation ${loadingTime} linear infinite`,
          }}
        >
          {text}
        </div>
        <div
          className="absolute top-0 left-0 h-full"
          style={{
            width: '3px', // Çizginin genişliği sabit (ince çizgi)
            backgroundColor: textColor,
            animation: `lineAnimation ${loadingTime} linear infinite`,
          }}
        />
      </div>
      <style jsx>{`
        @keyframes fillAnimation {
          0%, 100% {
            width: 0;
          }
          90% {
            width: 100%;
          }
        }

        @keyframes lineAnimation {
          0% {
            transform: translateX(0); /* Çizgi en soldan başlar */
          }
          90% {
            transform: translateX(${textWidth}px); /* Çizgi metin genişliği boyunca gider */
          }
        }
      `}</style>
    </div>
  )
}
