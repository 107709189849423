import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  Box,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginResponse } from '../../models/auth/login.model';
import { GetSubUploadedObjectMetadataResponseModel, GetUploadedObjectMetadataResponseModel } from '../../models/object/uploadedObject/get.uploadedObject.response.model';
import { GetUploadedObjectMetadataService } from '../../services/uploadedObject/get.uploaded.object.metadata.service';
import CircleLoading from '../../components/Loading/CircleLoading';

interface Metadata {
  id: string | null;
  properties: {
    Name: string;
    [key: string]: any;
  };
  objectId: string;
  externalId: string;
}

interface MetadataTableProps {
  metadata: GetUploadedObjectMetadataResponseModel[]
}

const MetadataTable: React.FC<MetadataTableProps> = ({ metadata }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderPropertyValue = (value: any): string => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return String(value);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="metadata table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Properties</TableCell>
              <TableCell>Object ID</TableCell>
              <TableCell>External ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
				metadata &&
				metadata
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell>{item.properties.Name}</TableCell>
                  <TableCell>
                    {Object.entries(item.properties).map(([key, value]) => (
                      <Box key={key} mb={1}>
                        <Typography variant="body2" component="span" fontWeight="bold">
                          {key}:
                        </Typography>{' '}
                        <Typography variant="body2" component="span">
                          {renderPropertyValue(value)}
                        </Typography>
                      </Box>
                    ))}
                  </TableCell>
                  <TableCell>{item.objectId}</TableCell>
                  <TableCell>{item.externalId.length>20?item.externalId.substring(0,20):item.externalId}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={metadata.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default function ObjectDetail() {
//   const metadata = [
//     // Your metadata array goes here
//     // For brevity, I'm not including all the data, but you should replace this with your full metadata array
//   ];

	const location = useLocation();
	const navigate = useNavigate();

	const urn=location.state.urn as string
	//const object = location.state.object as GetUploadedObjectResponseModel;
	const loginInfo = sessionStorage.getItem('login') as string;
	const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse

	const [object,setObject]=useState({
		metadata:[] as GetUploadedObjectMetadataResponseModel[]
	})

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10; // Number of items per page
	const handleBackClick = () => {
		navigate('/models');
	};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	// Pagination logic
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	// const currentItems = object.metadata !== undefined ? object.metadata.slice(indexOfFirstItem, indexOfLastItem) : null;

	useEffect(()=>{
		const getUploadedObjectMetadataService=new GetUploadedObjectMetadataService("objects","getModelDetail")
		getUploadedObjectMetadataService.GetByStringParamAsync(urn).then((response)=>{
			setObject({
				metadata:response.metadata
			})
		})
	},[urn])

	console.log(object)

  return (
    <>
    {
       object.metadata.length===0?(
         <CircleLoading text='Get to detail infos...' />
       ):(
        <Box sx={{ width: '100%', maxWidth: 1200, margin: 'auto', padding: 2 }}>
        <Typography variant="h4" gutterBottom>
          Model Detail Informations
        </Typography>
        <MetadataTable metadata={object.metadata.length>0 && object.metadata ? object.metadata:[]} />
      </Box>
       )
    }
    </>
  );
}