'use client'

import React, { useRef, useEffect, useState } from 'react'
import { motion, useInView, useAnimation, Variants, AnimatePresence } from 'framer-motion'
import { Box } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import Examples from '../pages/Examples'
import Configurator from '../pages/Configurator'

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

const sectionVariants: Variants = {
  hidden: { 
    opacity: 0, 
    y: isMobile ? 20 : 50 
  },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: isMobile ? 0.4 : 0.6,
      ease: [0.6, -0.05, 0.01, 0.99],
      staggerChildren: isMobile ? 0.1 : 0.2
    }
  }
}

const itemVariants: Variants = {
  hidden: { opacity: 0, y: isMobile ? 10 : 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: isMobile ? 0.3 : 0.5,
      ease: "easeOut"
    }
  }
}

const navigationButtonVariants: Variants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  }
}

export default function UsingArea() {
  const examplesRef = useRef<HTMLDivElement>(null)
  const configuratorRef = useRef<HTMLDivElement>(null)
  const examplesControls = useAnimation()
  const configuratorControls = useAnimation()
  const isExamplesInView = useInView(examplesRef, { once: false, amount: isMobile ? 0.1 : 0.3 })
  const isConfiguratorInView = useInView(configuratorRef, { once: false, amount: isMobile ? 0.1 : 0.3 })
  const [showGoDown, setShowGoDown] = useState(true)
  const [showGoUp, setShowGoUp] = useState(false)

  useEffect(() => {
    if (isExamplesInView) {
      examplesControls.start("visible")
    } else {
      examplesControls.start("hidden")
    }
  }, [isExamplesInView, examplesControls])

  useEffect(() => {
    if (isConfiguratorInView) {
      configuratorControls.start("visible")
    } else {
      configuratorControls.start("hidden")
    }
  }, [isConfiguratorInView, configuratorControls])

  useEffect(() => {
    window.history.scrollRestoration = 'manual'

    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const windowHeight = window.innerHeight
      const fullHeight = document.documentElement.scrollHeight

      setShowGoDown(scrollPosition < fullHeight - windowHeight - (isMobile ? 50 : 100))
      setShowGoUp(scrollPosition > (isMobile ? 50 : 100))
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const scrollTo = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const scrollToExamples = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  
  return (
    <>
      <Box ref={examplesRef} className="min-h-screen flex overflow-hidden">
        <motion.div
          initial="hidden"
          animate={examplesControls}
          variants={sectionVariants}
          className="w-full"
        >
          <motion.div variants={itemVariants}>
            <Examples />
          </motion.div>
        </motion.div>
      </Box>

      <Box className="min-h-screen flex items-center justify-center overflow-hidden">
        <motion.div
          ref={configuratorRef}
          initial="hidden"
          animate={configuratorControls}
          variants={sectionVariants}
          className="w-full"
        >
          <motion.div variants={itemVariants}>
            <Configurator />
          </motion.div>
        </motion.div>
      </Box>

      <AnimatePresence>
        {showGoDown && (
          <motion.button
            className={`fixed ${isMobile ? 'bottom-16 right-4' : 'bottom-24 right-8'} bg-white rounded-full p-3 shadow-lg`}
            onClick={() => scrollTo(configuratorRef)}
            variants={navigationButtonVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            whileHover={isMobile ? {} : { scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <KeyboardArrowDown fontSize={isMobile ? "medium" : "large"} />
            <span className="sr-only">Go to Configurator</span>
          </motion.button>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showGoUp && (
          <motion.button
            className={`fixed ${isMobile ? 'top-4 right-4' : 'top-8 right-8'} bg-white rounded-full p-3 shadow-lg`}
            onClick={() => scrollToExamples()}
            variants={navigationButtonVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            whileHover={isMobile ? {} : { scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <KeyboardArrowUp fontSize={isMobile ? "medium" : "large"} />
            <span className="sr-only">Go to İframes</span>
          </motion.button>
        )}
      </AnimatePresence>
    </>
  )
}