import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { CheckManifestUploadedObjectRequest, CheckManifestUploadedObjectResponse } from "../../../models/object/uploadedObject/check.manifest.uploadedObject.model";
import { CheckManifestUploadedObjectService } from "../../../services/uploadedObject/check.manifest.uploadeObject.service";




interface CheckManifestState extends BaseState<CheckManifestUploadedObjectResponse>{

}


let initialState:CheckManifestState={
    data:null,
    error:null,
    loading:false
}



export const checkManifestUploadedObject=createAsyncThunk("uploadedObject/checkManifest",
async(request:CheckManifestUploadedObjectRequest)=>{

    const service=new CheckManifestUploadedObjectService()
    const response=await service.GetByStringManyParamsAsync([request.urn,request.rootName,request.uid])
    return response
})


export const checkManifestUploadedObjectSlice=createSlice({
    name:"checkManifestUploadedObjectSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(checkManifestUploadedObject.pending,(state,action)=>{
            state.loading=true
        })
        .addCase( checkManifestUploadedObject.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(checkManifestUploadedObject.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
        
    }
})

export default  checkManifestUploadedObjectSlice.reducer











