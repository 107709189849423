import React from 'react';
import '../../styles/onedrive/multipleUploadModel.css'
import { Button, Form, Modal } from 'react-bootstrap';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { LinearWithValueLabel } from '../LinearProgressBar';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { baseUrl } from '../../config/axios.config';
import { fetchBoxFolder } from '../../store/features/box/get.box.folder.slice';
import LittleCircleLoading from '../Loading/LittleCircleLoading';
import { uploadBoxFilesToForge } from '../../store/features/box/upload.box.files.forge.slice';
import { GetFolderItemModel } from '../../models/oneDrive/folder/get.folder.item.model';
import { BoxData } from '../../pages/box/BoxUpload';
import { CheckManifestBoxService } from '../../services/box/check.manifest.box.service';
import { UploadSvfBoxService } from '../../services/box/upload.svf.box.service';
import { GetBoxIAMFilesResponse, GetIAMFilesModelResponse } from '../../models/oneDrive/file/get.iam.files.model';
import CircleLoading from '../Loading/CircleLoading';
import { Box, Checkbox, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { InsertDriveFile } from '@mui/icons-material';

interface AddedFileListProps {
    file: string;
    bucket: string | undefined
    id: string;
    handleRemoveClick: () => void;
    fileIds: string[]
}

interface MultipleBoxUploadModalProps {
    show: boolean;
    onHide: () => void;
    fileList: BoxData[];
    setFileList: React.Dispatch<React.SetStateAction<BoxData[]>>;
    bucket: string | undefined;
    setOpenZipOption:React.Dispatch<React.SetStateAction<boolean>>;
    iamFileList:GetBoxIAMFilesResponse|undefined
}

const AddedFileList: React.FC<AddedFileListProps> = ({
    file,
    bucket,
    id,
    handleRemoveClick,
    fileIds,
    
}) => {
    const [isPrompt, setIsPrompt] = useState(false);
	const [folder, setFolder] = useState({
		name: "",
		id: "",
		check: false
	})

    const dispatch = useAppDispatch();
    const folderSelector = useAppSelector((x) => x.getBoxFolderSlice);

    useEffect(() => {
        if (bucket && file) {
            const existFolderName = `${bucket}-${file}`;
            dispatch(fetchBoxFolder({
                folderName: existFolderName,
                bucketName: bucket
            }));
        }
    }, [bucket, file, dispatch]);

    useEffect(() => {
        if (
            folderSelector &&
            folderSelector.data &&
            folderSelector.data.folder &&
            !folderSelector.error &&
            !folderSelector.loading
        ) {
            const folder = folderSelector.data.folder as GetFolderItemModel;
            if (folder && folder.name && folder.id) {
                setFolder({
                    ...folder,
                    check: false
                });
                setIsPrompt(true);
            }
        }
    }, [folderSelector]);

    return (
        <>
            {id && file && (
                <div className='addedFile'>
                    <li id={`${id}`}>{file} </li>
                    {!folderSelector.loading ? (
                        <FontAwesomeIcon
                            className='trashIcon'
                            icon={faTrash}
                            onClick={(e) => {
                                handleRemoveClick();
                                e.preventDefault();
                            }}
                        />
                    ) : (
                        <LittleCircleLoading />
                    )}
                </div>
            )}
        </>
    );
};

export const MultipleBoxUploadModal: React.FC<MultipleBoxUploadModalProps> = ({
    show,
    onHide,
    fileList,
    setFileList,
    bucket,
    setOpenZipOption,
    iamFileList
}) => {
    const dispatch = useAppDispatch();
    const uploadIndexRef = useRef(0);

    const uploadboxFilesSelector = useAppSelector(
        (s: RootState) => s.uploadBoxFilesForgeSlice
    );
    const [progress, setProgress] = useState(0);
    const [step, setStep] = useState("");
    const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
    const [isConnected, setIsConnected] = useState(false);
    const [rootFile,setRootFile] = useState("")
    const [selectedFile, setSelectedFile] = useState({
        name: "",
        size: ""
    });
    const fetchIAMFilesSelector=useAppSelector((s: RootState) =>s.getBoxSubFilesSlice)
    const [progressText, setProgressText] = useState("")
    const [isContinueProgress, setIsContinueProgress] = useState(false)
    const [isVisible, setIsVisible] = useState(false);


    const startConnection = (fileIds: string[], bucketKey: string,rootFileNames:string[]) => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    console.log('Connection successfully established.');
                    setIsConnected(true);

                    if (fileIds.length > 2) {
                        fileIds.forEach((fileId) => {
                            dispatch(
                                uploadBoxFilesToForge({
                                    fileIds: [fileId],
                                    bucketKey,
                                    rootFileNames
                                })
                            );
                        });

                    } else {
                        dispatch(
                            uploadBoxFilesToForge({
                                fileIds,
                                bucketKey,
                                rootFileNames
                            })
                        );
                    }

                })
                .catch((err) =>
                    console.log('Error while establishing the connection: ', err)
                );
        }
    };

    const stopConnection = () => {
        if (connection && isConnected) {
            connection
                .stop()
                .then(() => {
                    console.log('Connection successfully stopped.');
                    setIsConnected(false);

                })
                .catch((err) => {
                    console.log('Error while stopping the connection: ', err)
                });
        }
    };

    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(`${baseUrl}progress`)
            .withAutomaticReconnect()
            .build();

        setConnection(connection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.on('ReceiveProgress', (progressValue, stepValue) => {
                console.log(progressValue)
                console.log(stepValue)
                setProgress(progressValue || 0);
                setStep(stepValue || '');
            });

            return () => {
                if (isConnected) {
                    connection
                        .stop()
                        .then(() => {
                            console.log("Connection successfully stopped.")
                        });
                }
            };
        }
    }, [connection, isConnected]);

    const uploadFilesSequentially = (fileIds: string[], bucketKey: string,rootFileNames:string[]) => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    console.log('Connection successfully established.');
                    setIsConnected(true);

                    const uploadNextFile = () => {
                        if (uploadIndexRef.current < fileIds.length) {
                            const fileId = fileIds[uploadIndexRef.current];
                            dispatch(
                                uploadBoxFilesToForge({
                                    fileIds: [fileId],
                                    bucketKey,
                                    rootFileNames
                                })
                            ).then(() => {
                                // Yükleme tamamlandı, bir sonraki dosyayı yükle
                                uploadIndexRef.current++;
                                uploadNextFile();
                            });
                        }
                    };

                    // İlk dosyayı başlat
                    uploadNextFile();
                })
                .catch((err) =>
                    console.log('Error while establishing the connection: ', err)
                );
        }
    };

    const checkManifestStatus = async (
        urn: string,
        fileName: string,
        uid: string,
    ) => {
        const manifestService = new CheckManifestBoxService()
        let isRequestInProgress = false;
        let requestTimeoutId: NodeJS.Timeout | null = null;
        const REQUEST_TIMEOUT = 60000; // 60 saniye
        const INTERVAL_DELAY = 10000; // 10 saniye

        const intervalId = setInterval(async () => {
            // Gerekli veriler var mı kontrol et
            if (!urn || !uid) {
                console.log("Gerekli veriler mevcut değil.");
                clearInterval(intervalId); 
                return;
            }
            if (isRequestInProgress) {
                console.log("İstek zaten devam ediyor, bekleniyor...");
                return;
            }

            isRequestInProgress = true;
          
            requestTimeoutId = setTimeout(() => {
                console.log("İstek 1 dakikadan uzun sürdü, yeni istek yapılabilir.");
                isRequestInProgress = false; // İstek durumunu sıfırla, yeni isteklere izin ver
            }, REQUEST_TIMEOUT); // 60 saniye timeout

            try {
                console.log("response öncesi")
                const response = await manifestService.GetByStringManyParamsAsync([urn, fileName, uid]);

                console.log(response.progress);
                setIsContinueProgress(true);
                setProgressText(response.progress)
                if (response.progress === "complete") {
                    setProgressText("Finished.")  
                    clearInterval(intervalId);
                    if (requestTimeoutId) clearTimeout(requestTimeoutId);
                    console.log("İşlem tamamlandı ve interval durduruldu.");
                    return;
                } else {
                    setProgressText(response.progress + "...")
                }
            } catch (error) {
                console.error("Bir hata oluştu:", error);
            } finally {
                isRequestInProgress = false;

                if (requestTimeoutId) {
                    clearTimeout(requestTimeoutId);
                    requestTimeoutId = null;
                }
            }
        }, INTERVAL_DELAY);
    };

    useEffect(() => {
        const { loading: uploadLoading, error: uploadError, data: uploadData } = uploadboxFilesSelector;

        if (!uploadLoading && uploadData && uploadData.isSuccess) {
            // Dosya başarıyla yüklendiğinde buraya girecek
            if (uploadIndexRef.current === fileList.length - 1) {
                // Eğer son dosyaysa, işlem tamamlandı
                console.log("All files uploaded successfully!");
                // İşlem tamamlandığında state'i sıfırla veya modal'i kapat
                // setFileList([]);
                checkManifestStatus(uploadData.urn[0], uploadData.fileName[0], uploadData.uid)
               // onHide();
            } else {
                // Bir sonraki dosyayı yükle
                uploadFilesSequentially(fileList.map((file) => file.id), bucket as string,[rootFile]);
            }
        } else if (!uploadLoading && uploadError) {
            // Hata durumunda
            console.error("Error uploading file:", uploadError);
            // İşlemi durdur veya gerekli adımları atla
        }
    }, [uploadboxFilesSelector, fileList, bucket, onHide, setFileList]);

    useEffect(() => {
        if (progressText === "Finished.") {
            setProgressText("Translating to SVF...")
            const uploadSvfFileService = new UploadSvfBoxService()
            uploadSvfFileService.UploadSvfWithFormDataAsync({
                bucketName: bucket as string,
                urn: uploadboxFilesSelector.data ? uploadboxFilesSelector.data.urn[0] : "",
                userId:  uploadboxFilesSelector.data ?  uploadboxFilesSelector.data.uid : "",
                mainFileName:  uploadboxFilesSelector.data ?  uploadboxFilesSelector.data.fileName[0] : ""
            }).then((response) => {
                setProgressText("")
                setIsContinueProgress(false)
                window.location.reload();
                onHide();
            })

        }
    }, [progressText])


    const handleSelectFileAsRoot = (name: string, size: number, fileName: string, id: string) => {
        setFileList((prev) => {
            return [...prev, {
                name: fileName,
                size,
                id: id,
                rootFileName: name
            }]
        })

        setOpenZipOption(false)
    }

    const handleselectedFile: any = (file: any) => {
        console.log(file)
        setSelectedFile(file)
    }

    return (
        
        <Modal
            show={show}
            onHide={() => {
                if(uploadboxFilesSelector.loading===false &&
                    uploadboxFilesSelector.data!==null && uploadboxFilesSelector.error===null){
                       window.location.reload();
                    }
                    else{
                        onHide();
                    }
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {
                    fetchIAMFilesSelector.loading  ? (
                        <div className="text-center">
                            <CircleLoading text='Extracting all files from zip'/>
                        </div>
                    ):(
                        <Form>         
                    <Form.Group>
                        {
                            iamFileList!==undefined && iamFileList.iamFiles.length>0 && rootFile===""?(
                                <Box className="bg-white p-6 min-w-[400px] max-w-2xl mx-auto-overflow-y-hidden">
                                            <Box className="flex items-center justify-between mb-4">
                                                <Typography variant="h5" component="h2" className="font-semibold text-gray-800">
                                                    Selected files
                                                </Typography>

                                            </Box>
                                            <Box className="mb-4">
                                                <Typography variant="subtitle1" className="font-medium text-gray-700">
                                                    Main file{!selectedFile.name && <span className='text-xs text-red-400'>{" ("}Please select the main file.{")"}</span>}
                                                </Typography>
                                                {selectedFile.name && <Box className="flex items-center justify-between p-2 bg-gray-50 rounded">
                                                    <Box className="flex items-center">
                                                        <Checkbox
                                                            edge="start"
                                                            checked={true}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            className="text-blue-500"
                                                        />
                                                        <InsertDriveFile className="text-gray-400 mr-2" />
                                                        <Typography variant="body2">{selectedFile.name.split("/")[selectedFile.name.split("/").length - 1]}</Typography>
                                                    </Box>
                                                    <Box className="flex items-center">
                                                        <Typography variant="body2" className="text-gray-500 mr-2">
                                                            {selectedFile.size}
                                                        </Typography>
                                                    </Box>
                                                </Box>}
                                            </Box>
                                            <Box className="mb-4">
                                                <Typography variant="subtitle1" className="font-medium text-gray-700 mb-2">
                                                    Referans dosyalar {iamFileList.iamFiles.length}
                                                </Typography>
                                                <List className="divide-y divide-gray-200 max-h-[50vh] overflow-auto">
                                                    {iamFileList.iamFiles.map((file, i) => (
                                                        selectedFile.name != file.name &&
                                                        <ListItem
                                                            key={i}
                                                            dense
                                                            button
                                                            onClick={() => handleselectedFile(file)}
                                                            className="py-1"
                                                        >
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    tabIndex={-1}
                                                                    disableRipple

                                                                    checked={selectedFile.name == file.name}
                                                                    className="text-blue-500"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemIcon>
                                                                <InsertDriveFile className="text-gray-400" />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={file.name.split('/')[file.name.split('/').length - 1]}
                                                                className="text-sm"
                                                            />
                                                            <Typography variant="body2" className="text-gray-500">
                                                                {file.size}
                                                            </Typography>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Box>
                                            <Box className="flex flex-col items-center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={selectedFile?.name?.length < 1}
                                                    className="w-full max-w-xs border  hover:!bg-gray-200"
                                                    onClick={() => {
                                                        const splitList = selectedFile?.name?.split("/")
                                                        const newRootFileName = splitList[splitList?.length - 1]
                                                        setRootFile(newRootFileName)
                                                        setOpenZipOption(false)
                                                        setIsVisible(true);

                                                        console.log(selectedFile)
                                                    }}
                                                >
                                                    CONTINUE
                                                </Button>
                                            </Box>
                                        </Box>
                            ):(
                                <>
                                {fileList.map((file) => (
                                    <AddedFileList
                                        key={file.name}
                                        fileIds={fileList.map(file => file.id)}
                                        file={file.name || ""}
                                        handleRemoveClick={() => {
                                            const index = fileList.indexOf(file);
                                            if (index > -1) {
                                                if (index === 0) {
                                                    setFileList([]);
                                                } else {
                                                    setFileList((prev) => {
                                                        prev = prev.splice(index, 1);
                                                        return prev;
                                                    });
                                                }
                                            }
                                        }}
                                        id={file.id}
                                        bucket={bucket}
                                    />
                                ))}
                              </>
                            
                            )
                           
                        }
                      </Form.Group>
                </Form>
                    )
                }
                {/* <Form>
                    <Form.Group>
                        <Form.Label>{fileList.length > 1 ? "Files" : "File"}</Form.Label>
                        {fileList.map((file) => (
                            <AddedFileList
                                key={file.id}
                                fileIds={fileList.map(file => file.id)}
                                file={file.name || ""}
                                handleRemoveClick={() => {
                                    const index = fileList.indexOf(file);
                                    if (index > -1) {
                                        if (index === 0) {
                                            setFileList([]);
                                        } else {
                                            setFileList((prev) => {
                                                prev = prev.splice(index, 1);
                                                return prev;
                                            });
                                        }
                                    }
                                }}
                                id={file.id || ""}
                                bucket={bucket}
                            />
                        ))}
                    </Form.Group>
                </Form> */}
            </Modal.Body>
            <Modal.Footer>
                {uploadboxFilesSelector.data && !uploadboxFilesSelector.loading &&
                    !uploadboxFilesSelector.error &&
                    uploadboxFilesSelector.data.isSuccess && progress === 100 && (
                        <>
                            {fileList.length > 1 ? (
                                <p style={{ color: "green", marginRight: "auto" }}>
                                    Files have been uploaded succesfully
                                </p>
                            ) : (
                                    <p style={{ color: "green", marginRight: "auto" }}>
                                        File has been uploaded successfully
                                    </p>
                                )}
                        </>
                    )}
                {bucket && (
                    <Button
                        className='uploadButton'
                        disabled={uploadboxFilesSelector.loading || fileList.length === 0}
                        variant={uploadboxFilesSelector.loading ? "secondary" : "primary"}
                        hidden={progress === 100}
                        onClick={() => {
                            uploadFilesSequentially(fileList.map((file) => file.id), bucket as string,[rootFile]);
                        }}
                    >
                        Translate {fileList.length > 1 ? "Files" : "File"}
                    </Button>
                )}

                {uploadboxFilesSelector.loading && (
                    <LinearWithValueLabel progress={progress} step={step} />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default MultipleBoxUploadModal;
