import React, { useEffect, useState } from 'react';
import '../../styles/onedrive/upload.css';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import CircleLoading from '../Loading/CircleLoading';
import { createUploadedObject, resetCreateState } from '../../store/features/object/create.uploadedObject.slice';
import { getUploadedObjects } from '../../store/features/object/get.list.uploadedObject.slice';
import { UploadedFile } from '../../models/object/uploadedObject/put.uploadedObject.request.model';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SelectableZipFiles } from '../../pages/object/ObjectList';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Checkbox, IconButton, Button, Dialog, DialogTitle, DialogContent, Grid, DialogActions, TextField, Select, MenuItem } from '@mui/material';
import { InsertDriveFile, Search, Sort } from '@mui/icons-material';
import FileFormats from './FileFormats';
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import { baseUrl } from '../../config/axios.config';
import { CheckManifestUploadedObjectService } from '../../services/uploadedObject/check.manifest.uploadeObject.service';
import { resetCreditError } from '../../store/features/object/check.credit.slice';
import { resetCreditWhenUploadError } from '../../store/features/object/check.credit.when.upload.slice';
import JSZip from "jszip";
import path from "path-browserify"

interface DragAndDropProps {
	handleFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleCloseModal: () => void;
	handleFileDrop: (e: React.DragEvent<HTMLDivElement>) => void;
	setIsDragDrop: React.Dispatch<React.SetStateAction<boolean>>;
	selectedFiles: UploadedFile[];
	setSelectedFiles: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
	setZipFile: React.Dispatch<React.SetStateAction<SelectableZipFiles | undefined>>;
	zipFile: SelectableZipFiles | undefined;
	bucket: string;
	processZipFile: (file: File) => Promise<void>
	openZipOption: boolean
	setOpenZipOption: React.Dispatch<React.SetStateAction<boolean>>
	extractZipStatus: boolean;
	setExtractZipStatus: React.Dispatch<React.SetStateAction<boolean>>
	creditStatus: string;
	setCreditStatus: React.Dispatch<React.SetStateAction<string>>
	invalidFileMessage: string;
	setInvalidFileMessage: React.Dispatch<React.SetStateAction<string>>
}

const MultipleUploadModal: React.FC<DragAndDropProps> = ({
	handleFile,
	handleFileDrop,
	setIsDragDrop,
	selectedFiles,
	setSelectedFiles,
	bucket,
	zipFile,
	setZipFile,
	processZipFile,
	setOpenZipOption,
	openZipOption,
	setExtractZipStatus,
	extractZipStatus,
	creditStatus,
	setCreditStatus,
	invalidFileMessage,
	setInvalidFileMessage
}) => {
	const dispatch = useAppDispatch();
	const [isFinishedToLoading, setIsFinishedToLoading] = useState(false);
	const uploadedFilesSelector = useAppSelector((s: RootState) => s.createUploadedObject);
	const UploadFilesSwal = withReactContent(Swal);
	const files = uploadedFilesSelector.data?.files as string[]
	const urns = uploadedFilesSelector.data?.urns as string[];
	const uid = uploadedFilesSelector.data?.uid as string;
	const [manifestStatus, setManifestStatus] = useState("");
	const [selectedFile, setSelectedFile] = useState({
		fileName: "",
		file: "",
		fileSize: "",
	});

	const [showSupportedFormats, setShowSupportedFormats] = useState(false);
	const supportedFormats = [
		"3DM", "3DS", "A", "ASM", "AXM", "BRD", "CATPART", "CATPRODUCT", "CGR", "COLLABORATION", "DAE", "DDX", "DDZ", "DGK",
		"DGN", "DLV3", "DMT", "DWF", "DWFX", "DWG", "DWT", "DXF", "EMODEL", "EXP", "F3D", "FBRD", "FBX", "FSCH", "G", "GBXML",
		"GLB", "GLTF", "IAM", "IDW", "IFC", "IGE", "IGES", "IGS", "IPT", "IWM", "JT", "MAX", "MODEL", "MPF", "MSR", "NEU",
		"NEW", "NWC", "NWD", "OBJ", "OSB", "PAR", "PMLPRJ", "PMLPRJZ", "PRT", "PSM", "PSMODEL", "RVM", "RVT", "SAB", "SAT",
		"SCH", "SESSION", "SKP", "SLDASM", "SLDPRT", "SMB", "SMT", "STE", "STEP", "STL", "STLA", "STLB", "STP", "STPZ", "VPB",
		"VUE", "WIRE", "X_B", "X_T", "XAS", "XPR"
	];
	const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
	const [isConnected, setIsConnected] = useState(false);
	const [progress, setProgress] = useState(0);
	const [step, setStep] = useState("");

	const [afterUploadError, setAfterUploadError] = useState("");

	const checkCreditSelector = useAppSelector(x => x.checkCreditUploadedObjectSlice)
	const checkCreditWhenUploadSelector = useAppSelector(x => x.checkCreditUploadedObjectWhenUploadSlice)
	const handleselectedFile: any = (file: any) => {
		setSelectedFile(file)
	}

	const [searchTerm, setSearchTerm] = useState('');
	const [sortBy, setSortBy] = useState('name');
	const [sortOrder, setSortOrder] = useState('asc');

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, []);

	const handleFileInputWithDrop = async (e: React.DragEvent<HTMLDivElement>): Promise<void> => {
		e.preventDefault()
		const files: File[] = Array.from(e.dataTransfer.files || []);
		handleFileDrop(e);

		for (const file of files) {
			if (file.name.endsWith('.zip')) {
				await processZipFile(file);
			}
		}

		e.dataTransfer.clearData()
	}

	const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		const files: File[] = Array.from(e.target.files || []);
		handleFile(e);
		for (const file of files) {
			if (file.name.endsWith('.zip')) {
				await processZipFile(file);
			}
		}

		e.target.value = '';
	};

	const handleSelectFileAsRoot = async (file: File, rootFileName: string) => {
		if (file.type === "application/zip" || file.type === "application/x-zip-compressed") {

			const zip = new JSZip();
			const zipContent = await zip.loadAsync(file);
			const flattenedZip = new JSZip();
			const fileNameSet = new Set();

			for (const relativePath of Object.keys(zipContent.files)) {
				const zipEntry = zipContent.files[relativePath];
				if (!zipEntry.dir) {
					const fileBlob = await zipEntry.async("blob");
					let fileName = path.basename(zipEntry.name);
					fileName = fileName.indexOf("\\") !== -1 ? fileName.split("\\").pop() as string : fileName
					fileName = fileName.normalize('NFC');
					let originalFileName = fileName;
					let counter = 1;
					while (fileNameSet.has(fileName)) {

						const fileExtension = path.extname(originalFileName);
						const fileNameWithoutExtension = path.basename(originalFileName, fileExtension);
						fileName = `${fileNameWithoutExtension}(${counter})${fileExtension}`;
						counter++;

					}
					fileNameSet.add(fileName);
					if (fileName) {
						flattenedZip.file(fileName, fileBlob);
					}

				}

			}

			const flattenedZipBlob = await flattenedZip.generateAsync({ type: "blob" });
			const flattenedZipFile = new File([flattenedZipBlob], rootFileName, { type: "application/zip" });
			const newFlattenedZip = await zip.loadAsync(flattenedZipFile);

			for (const relativePath of Object.keys(newFlattenedZip.files)) {

				console.log(relativePath);

			}

			setSelectedFiles([{
				file: flattenedZipFile,
				rootFileName: rootFileName
			}]);
			setOpenZipOption(false);

		} else {
			setSelectedFiles([{
				file: file,
				rootFileName: rootFileName
			}]);

			setOpenZipOption(false);
		}

	};

	// Manifest kontrolünü yapan fonksiyon
	const checkManifestStatus = async (
		urns: string[],
		files: string[],
		uid: string,
	) => {

		setManifestStatus("Translate starting...")
		const manifestService = new CheckManifestUploadedObjectService();
		let isRequestInProgress = false; // İstek durumu flag'i
		let requestTimeoutId: NodeJS.Timeout | null = null; // İstek zaman aşımı için timeout ID'si
		const REQUEST_TIMEOUT = 60000; // 60 saniye
		const INTERVAL_DELAY = 10000; // 10 saniye

		const intervalId = setInterval(async () => {
			// Gerekli veriler var mı kontrol et
			if (!urns || urns.length === 0 || !uid) {
				console.log("Gerekli veriler mevcut değil.");
				clearInterval(intervalId); // Gerekli veriler yoksa interval'i durdur
				return;
			}
			if (isRequestInProgress) {
				console.log("İstek zaten devam ediyor, bekleniyor...");
				return;
			}
			isRequestInProgress = true;
			requestTimeoutId = setTimeout(() => {
				console.log("İstek 1 dakikadan uzun sürdü, yeni istek yapılabilir.");
				isRequestInProgress = false;
			}, REQUEST_TIMEOUT);

			try {
				const response = await manifestService.GetByStringManyParamsAsync([urns[0], files[0], uid]);
				if (response.progress === "complete") {
					setManifestStatus("Finishing...");
					dispatch(resetCreateState())
					setSelectedFiles([])
					setIsFinishedToLoading(true)
					clearInterval(intervalId); // Interval'i durdur
					if (requestTimeoutId) clearTimeout(requestTimeoutId);
				} else {
					setManifestStatus(response.progress + "...");
				}
			} catch (error: any) {
				console.error("Error:", error);
				setAfterUploadError(error.message !== undefined ? error.message : error.statusText)
			} finally {
				isRequestInProgress = false;

				if (requestTimeoutId) {
					clearTimeout(requestTimeoutId);
					requestTimeoutId = null;
				}
			}
		}, INTERVAL_DELAY);
	};

	useEffect(() => {
		const connection = new HubConnectionBuilder()
			.withUrl(`${baseUrl}progress`,{
				transport:HttpTransportType.LongPolling
			})
			.withAutomaticReconnect()
			.build();
		setConnection(connection);
	}, []);

	useEffect(() => {
		if (connection) {
			connection.on('ReceiveProgress', (progressValue, stepValue) => {
				console.log(progressValue)
				console.log(stepValue)
				setProgress(progressValue || 0);
				setStep(stepValue || '');
				
			});

			return () => {
				if (isConnected) {
					connection
						.stop()
						.then(() => {
							console.log("Connection successfully stopped.")
						});
				}
			};
		}
	}, [connection, isConnected]);

	useEffect(() => {
		if (selectedFiles.length > 0 && urns && uid && manifestStatus !== "Finishing...") {
			const fetchData = async () => {
				await checkManifestStatus(urns, files, uid);
			};

			fetchData();
		}
	}, [selectedFiles.length, urns, uid, manifestStatus]);

	const filteredAndSortedFiles = React.useMemo(() => {
		if (zipFile && zipFile.subFiles) {
			return zipFile.subFiles
				.filter(file => file.fileName.toLowerCase().includes(searchTerm.toLowerCase()))
				.sort((a, b) => {
					if (sortBy === 'name') {
						return sortOrder === 'asc' 
							? a.fileName.localeCompare(b.fileName)
							: b.fileName.localeCompare(a.fileName);
					} else {
						return sortOrder === 'asc'
							? parseFloat(a.fileSize.toString()) - parseFloat(b.fileSize.toString())
							: parseFloat(b.fileSize.toString()) - parseFloat(a.fileSize.toString());
					}
				});
		}
		return [];
	}, [zipFile, searchTerm, sortBy, sortOrder]);

	return (
		<div className='dnd-modal'>
			<div className='dnd-container'>
				<button
					className='close-button'
					hidden={uploadedFilesSelector.loading}
					onClick={() => {
						setZipFile(undefined);
						setSelectedFiles([])
						if (isFinishedToLoading) {
							setIsFinishedToLoading(false);
							dispatch(getUploadedObjects({ bucketKey: bucket }));
							setIsDragDrop(false);
						} else {
							setIsDragDrop(false);
						}

						if (checkCreditWhenUploadSelector.error !== null) {
							dispatch(resetCreditWhenUploadError())
						}

						if (uploadedFilesSelector.error !== null) {
							dispatch(resetCreateState())
						}

						if (invalidFileMessage !== "") {
							setInvalidFileMessage("")
						}

						if(afterUploadError!==""){
							setAfterUploadError("")
						}

					}}
				>
					<svg className='close-icon' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style={{ width: '50px', height: '50px' }}>
						<path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' />
						<path d='M0 0h24v24H0z' fill='none' />
					</svg>
				</button>
				{
					extractZipStatus === true ? (
						<CircleLoading text='Extracting all files to zip...' />
					) : (
						<>
							{
								zipFile !== undefined && openZipOption === true ? (
									<Box className="bg-white rounded-lg p-6 min-w-[500px] max-w-2xl mx-auto overflow-y-hidden border min-h-[600px]">
										<Box className="flex items-center justify-between mb-4">
											<Typography variant="h5" component="h2" className="font-semibold text-gray-800">
												Selected files
											</Typography>
										</Box>
										<Box className="mb-4">
											<Typography variant="subtitle1" className="font-medium text-gray-700">
												Main file{!selectedFile.fileName && <span className='text-xs text-red-400'>{" ("}Please select the main file.{")"}</span>}
											</Typography>
											{selectedFile.fileName && <Box className="flex items-center justify-between p-2 rounded">
												<Box className="flex items-center">
													<Checkbox
														edge="start"
														checked={true}
														tabIndex={-1}
														disableRipple
														className="text-blue-500"
													/>
													<InsertDriveFile className="text-gray-400 mr-2" />
													<Typography variant="body2">{selectedFile.fileName}</Typography>
												</Box>
												<Box className="flex items-center">
													<Typography variant="body2" className="text-gray-500 mr-2">
														{selectedFile.fileSize} KB
													</Typography>
												</Box>
											</Box>}
										</Box>
										<Box className="mb-4 scroll-y">
											<Typography variant="subtitle1" className="font-medium text-gray-700 mb-2">
												References Files {"(" + zipFile.subFiles.length + ")"}
											</Typography>
											<Box className="flex items-center mb-2 justify-between">
												<TextField
													size="small"
													placeholder="Search files..."
													value={searchTerm}
													onChange={(e) => setSearchTerm(e.target.value)}
													InputProps={{
														startAdornment: <Search />,
													}}
												/>
												<Select
													size="small"
													value={sortBy}
													onChange={(e) => setSortBy(e.target.value)}
													className="ml-2"
												>
													<MenuItem value="name">Name</MenuItem>
													<MenuItem value="size">Size</MenuItem>
												</Select>
												<IconButton onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}>
													<Sort />
												</IconButton>
											</Box>
											<List className="divide-y divide-gray-200 max-h-[50vh] overflow-auto">
												{filteredAndSortedFiles.map((file, i) => (
													selectedFile.fileName != file.fileName &&
													<ListItem
														key={i}
														dense
														button
														onClick={() => handleselectedFile(file)}
														className="py-1"
													>
														<ListItemIcon>
															<Checkbox
																edge="start"
																tabIndex={-1}
																disableRipple
																checked={selectedFile.fileName == file.fileName}
																className="text-blue-500"
															/>
														</ListItemIcon>
														<ListItemIcon>
															<InsertDriveFile className="text-gray-400" />
														</ListItemIcon>
														<ListItemText
															primary={file.fileName}
															className="text-sm"
														/>
														<Typography variant="body2" className="text-gray-500">
															{file.fileSize} KB
														</Typography>
													</ListItem>
												))}
											</List>
										</Box>
										<Box className="flex flex-col items-center">
											<Button
												variant="contained"
												color="primary"
												disabled={selectedFile.fileName.length < 1}
												className="w-full max-w-xs"
												onClick={() => handleSelectFileAsRoot(zipFile.mainZipFile, selectedFile.fileName)}
											>
												CONTINUE
											</Button>
										</Box>
									</Box>
								) : (
									<>
										{
											(
												uploadedFilesSelector && uploadedFilesSelector.error !== null) ||
												(checkCreditWhenUploadSelector && checkCreditWhenUploadSelector.error !== null) ||
												invalidFileMessage !== "" ? (
												<label className='dnd-label'>
													<div className='dnd-desc'>
														<div>
															<p>
																<b>{uploadedFilesSelector || invalidFileMessage !== "" || afterUploadError !== "" ? "Error" : 
																checkCreditWhenUploadSelector.error!==undefined?"Insufficent Credit":"Error"}</b>
															</p>
															<div style={{
																color: "red",
																display: "block",
																marginTop: "10px;"
															}}>
																<br />
																<b>
																	{(() => {
																		if (uploadedFilesSelector.error) {
																			return typeof uploadedFilesSelector.error === 'object' && uploadedFilesSelector.error.error
																				? uploadedFilesSelector.error.error
																				: uploadedFilesSelector.error;
																		}
																		if (invalidFileMessage) return invalidFileMessage;
																		if (afterUploadError) return afterUploadError;
																		if (checkCreditWhenUploadSelector.error) return checkCreditWhenUploadSelector.error.message;
																		return '';
																	})()}
																</b>
															</div>
														</div>
														<div className='dnd-upload-btn-grp'>
															<button
																className='dnd-cancel-btn'
																hidden={
																	uploadedFilesSelector.loading ? true : false ||
																		isFinishedToLoading ? true : false
																}
																onClick={() => {
																	dispatch(resetCreateState())
																	dispatch(resetCreditError())
																	dispatch(resetCreditWhenUploadError())
																	setInvalidFileMessage("")
																	setAfterUploadError("")
																	setSelectedFiles([])
																	setIsDragDrop(false)
																}}
															>
																Cancel
															</button>
														</div>
													</div>
												</label>
											) : (
												<label className='dnd-label'>
													<input
														type='file'
														multiple
														onChange={(e) => {
															handleFileInput(e)
														}}
														disabled={uploadedFilesSelector.loading ? true : false}
														hidden={uploadedFilesSelector.loading ? true : false}
														style={{ display: 'none' }}
													/>
													<div
														onDrop={(e) => {
															handleFileInputWithDrop(e)
														}}
														onDragOver={(e) => e.preventDefault()}
														className='drag-drop-area'
													>
														<div className='svg-container'>
															<svg
																className='svg-icon'
																viewBox='0 0 24 24'
																xmlns='http://www.w3.org/2000/svg'
															>
																<g>
																	<path
																		fill='none'
																		d='M0 0h24v24H0z'
																	/>
																	<path
																		className='hover-path'
																		fill-rule='nonzero'
																		d='M14 6h2v2h5a1 1 0 0 1 1 1v7.5L16 13l.036 8.062 2.223-2.15L20.041 22H9a1 1 0 0 1-1-1v-5H6v-2h2V9a1 1 0 0 1 1-1h5V6zm8 11.338V21a1 1 0 0 1-.048.307l-1.96-3.394L22 17.338zM4 14v2H2v-2h2zm0-4v2H2v-2h2zm0-4v2H2V6h2zm0-4v2H2V2h2zm4 0v2H6V2h2zm4 0v2h-2V2h2zm4 0v2h-2V2h2z'
																	/>
																</g>
															</svg>
														</div>
														<div className='dnd-desc'>
															<div>
																<p>
																	<b>Drag & Drop or Browse</b>
																</p>
																<>
																	<p>We support IPT,ZIP,DWG,DWFX,RVT and more...
																		<br />
																		You can select a maximum of 15 files to upload
																	</p>
																</>
															</div>
														</div>

														<br />
														{
															<div className='dnd-files'>
																{uploadedFilesSelector.loading || (
																	manifestStatus === "" &&
																	uploadedFilesSelector.data !== null && uploadedFilesSelector.error === null) ? (
																	<>
																		<CircleLoading text={step!==""?step:"Uploading..."} />
																	</>
																) :

																	uploadedFilesSelector.error == null && uploadedFilesSelector.data != null ? (
																		<div>

																			<span>
																				<CircleLoading text={manifestStatus} />
																			</span>

																		</div>
																	) : manifestStatus === "Finishing..." ? (
																		<div>
																			<span>
																				<h3 style={{
																					color: "green"
																				}}>Finished.</h3>
																			</span>
																		</div>
																	) :

																		(

																			<>
																				{selectedFiles &&
																					selectedFiles.length > 0 &&
																					selectedFiles.map((file, i) => (
																						<div
																							className='addedFile'
																							style={{
																								display: 'flex',
																								alignItems: 'center',
																							}}
																						>
																							<span
																								style={{
																									marginLeft: '0.5rem',
																								}}
																							>
																								{file.file.name}
																							</span>
																						</div>
																					))}
																			</>
																		)}
															</div>
														}
													</div>
													<div className='dnd-upload-btn-grp'>
														<button
															className='dnd-cancel-btn'
															hidden={
																uploadedFilesSelector.loading ? true : false ||
																	isFinishedToLoading ? true : false
															}
															onClick={() => {
																setSelectedFiles([])
																setIsDragDrop(false)
															}}
														>
															Cancel
														</button>
														{selectedFiles && selectedFiles.length > 0 && (

															<button
																className='dnd-upload-btn'
																hidden={uploadedFilesSelector.loading}
																onClick={async () => {
																	// if(connection){
																	// 	setIsConnected(true)
																	// 	connection.start().then(()=>{
																	// 		console.log('Connection successfully established.');
																	// 		setIsConnected(true);
																	// 		dispatch(createUploadedObject({
																	// 			bucketName: bucket,
																	// 			files: [...selectedFiles]
																	// 		})).then(() => {
																	// 			setSelectedFiles([])
																	// 			setIsFinishedToLoading(true)
		
																	// 		})
																	// 	}).catch((err) =>
																	// 		console.log('Error while establishing the connection: ', err)
																	// 	)
																	// }

																	// else{
																		dispatch(createUploadedObject({
																			bucketName: bucket,
																			files: [...selectedFiles]
																		})).then(() => {
																			setSelectedFiles([])
																			setIsFinishedToLoading(true)
	
																		})
																	// }
																

																}}
															>
																Upload
															</button>
														)}
														{
															showSupportedFormats && (
																<Dialog
																	open={showSupportedFormats}
																	onClose={() => setShowSupportedFormats(false)}
																	sx={{
																		'& .MuiDialog-paper': {
																			width: '80%',
																			maxWidth: '600px',
																			maxHeight: '80vh',
																		},
																	}}
																>
																	<DialogContent>
																		<FileFormats />
																	</DialogContent>
																	<DialogActions>
																		<Button onClick={() => setShowSupportedFormats(false)}>Close</Button>
																	</DialogActions>
																</Dialog>
															)
														}
													</div>
												</label>
											)
										}

										<br />
										<Typography
											variant="body2"
											color="primary"
											sx={{ cursor: 'pointer', textDecoration: 'underline', mb: 2 }}
											onClick={() => {
												setShowSupportedFormats(true)
											}}
										>
											See all supported formats
										</Typography>
									</>
								)
							}
						</>
					)
				}
			</div>
		</div>
	);
};

export default MultipleUploadModal;