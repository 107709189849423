import { AdminLoginRequestModel, AdminLoginResponseModel } from "../../models/admin/admin.login.model";
import { BasePostService } from "../base/base.post.service";



export class AdminLoginService extends BasePostService<AdminLoginRequestModel,AdminLoginResponseModel> 
{
    constructor(controllerName:string="admin",endpointName:string="login"){
        super(controllerName, endpointName)
    }
}