import video1 from '../assets/videos/Sequence 01 1.mp4';
import video2 from '../assets/videos/zımba.mp4';
import video3 from '../assets/videos/6895614-hd_1920_1080_25fps.mp4';


// ! challenge solution ve benefit tek bir obje içerisine alınıp ona göre renderlanıyor. Position videonun sağda ya da solda olmasına karar veriyor.

const sections = [
  {
    id: 1,
    title: 'Challenge',
    description:
      "Traditional product catalogs struggle to showcase product's full potential and details. 2D images fail to accurately represent the actual size, functionality, and features of products. Moreover, customers cannot explore and interact with these catalogs in detail, leading to dissatisfaction and low sales conversion rates.",
    video: video1,
    position: 'left',
  },
  {
    id: 2,
    title: 'Solution',
    description:
      'VEEWER revolutionizes your product catalogs by adding a new dimension. With 3D technology, you can present your products more impressively and interactively. Customers can examine your products 360 degrees, zoom in, and interact with them. VEEWER enhances the shopping experience, increasing customer trust and engagement with your products.',
    video: video2,
    position: 'right',
  },
  {
    id: 3,
    title: 'Benefits',
    description: (
      <ul className="space-y-3 px-0 list-inside text-gray-700">
        <li className="flex items-start">
          <span className="mr-2 text-green-500">✓</span>
          <span className='text-[clamp(0.8rem,2vw,1.2rem) ]'>Your customers will better understand your products and make purchasing decisions with confidence.</span>
        </li>
        <li className="flex items-start">
          <span className="mr-2 text-green-500">✓</span>
          <span className='text-[clamp(0.8rem,2vw,1.2rem) ]'>Interactive and detailed product exploration drives higher purchase rates.</span>
        </li>
        <li className="flex items-start">
          <span className="mr-2 text-green-500">✓</span>
          <span className='text-[clamp(0.8rem,2vw,1.2rem) ]'>Stand out in your industry and stay ahead of the competition with VEEWER.</span>
        </li>
        <li className="flex items-start">
          <span className="mr-2 text-green-500">✓</span>
          <span className='text-[clamp(0.8rem,2vw,1.2rem) ]'>A more cost-effective and eco-friendly solution compared to traditional catalogs, protecting your business budget.</span>
        </li>
      </ul>
    ),
    video: video3,
    position: 'left',
  },
];



export default function ChallengeSolution() {
  return (
    <section className="py-16 bg-white">
      <div className="md:container  w-full mx-auto">
        <div className="space-y-24">
          {sections.map((section) => (
            <div
              key={section.id}
              className={`flex flex-col ${section.position === 'right' ? 'md:flex-row-reverse ' : 'md:flex-row'} items-center md:items-start gap-8 px-3`}
            >
              {/* Video Section */}
              <div className="w-full md:w-1/2 aspect-video">
                <video autoPlay loop muted className="w-full h-full object-cover rounded-lg">
                  <source src={section.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              {/* Text Section */}
              <div className="w-full md:w-1/2">
                <h2 className="text-2xl md:text-3xl font-bold mb-2">{section.title}</h2>
                <p className="text-gray-600 text-[clamp(0.8rem,2vw,1.2rem) ]">{section.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
