import { TokenResponse } from "../../models/auth/auth.token.model";
import { TokenRefreshResponse } from "../../models/auth/refresh.token.model";
import { BaseGetService } from "../base/base.get.service";


export class TokenRefreshService extends BaseGetService<TokenRefreshResponse>{

    constructor(controllerName:string="auth",endpointName:string="refresh"){
        super(controllerName,endpointName)
    }
}