import { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { fetchgetUserList } from "../store/features/admin/get.userlist.slice";
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { useForm, FormProvider } from 'react-hook-form';
import { GetUserListModel } from '../models/admin/get.userlist.model';
import { AdminLoginResponseModel } from '../models/admin/admin.login.model';



export default function AdminDashboard() {
    const getUserListSlice = useAppSelector((state: RootState) => state.getUserListSlice);
    const dispatch = useAppDispatch();
    const methods = useForm({
        defaultValues: {
            searchTerm: '',
            companyFilter: '',
        },
    });

    useEffect(() => {
        dispatch(fetchgetUserList());
    }, [dispatch]);

    const onSubmit = (data: { searchTerm: string; companyFilter: string }) => {
        // You can handle form submission here if needed
        console.log(data);
    };

    const { watch } = methods;
    const searchTerm = watch('searchTerm');
    const companyFilter = watch('companyFilter');

    const [sortField, setSortField] = useState<'usedCredit' | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

    const filteredData = getUserListSlice.data ? getUserListSlice.data.filter((item: GetUserListModel) =>
        (item.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.lastName.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (companyFilter === '' || item.companyName === companyFilter)
    ).sort((a, b) => {
        if (sortField === 'usedCredit') {
          return sortDirection === 'asc' ? a.usedCredit - b.usedCredit : b.usedCredit - a.usedCredit;
        }
        return 0;
      }) : []

    console.log(filteredData)
    const uniqueCompanies = getUserListSlice.data ?
        Array.from(new Set(getUserListSlice.data.map((item: GetUserListModel) => item.companyName))) : []

    

    const adminLoginInfo = sessionStorage.getItem('adminLoginInfo') as string
    const jsonAdminLoginInfo = JSON.parse(adminLoginInfo) as AdminLoginResponseModel

    const chartData = getUserListSlice.data?.filter(x =>
        x.firstName.includes("Veewer") === false &&
        x.lastName.includes("Veewer") === false &&
        x.companyName.includes("Veewer") === false &&
        x.companyName !== ""
    ).map((x) => ({
        name: `${x.firstName} ${x.lastName}`,
        usedCredit: x.usedCredit,
        availableCredit: x.availableCredit,
        totalCredit: x.usedCredit + x.availableCredit
    })).sort((a, b) => b.totalCredit - a.totalCredit)


    return (
        <>
            {
                // adminLoginInfo && jsonAdminLoginInfo.isAuthenticated ? (
                <FormProvider {...methods}>
                    <div>
                        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h6" gutterBottom>
                                            Credit Usage
                                        </Typography>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={chartData}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" angle={-45} textAnchor="end" height={80} />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="usedCredit" stackId="a" fill="hsl(var(--primary))" name="Used Credit" />
                                                <Bar dataKey="availableCredit" stackId="a" fill="hsl(var(--secondary))" name="Available Credit" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h6" gutterBottom>
                                            User List
                                        </Typography>
                                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        fullWidth
                                                        label="Search User"
                                                        variant="outlined"
                                                        {...methods.register('searchTerm')}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel>Company</InputLabel>
                                                        <Select
                                                            label="Company"
                                                            {...methods.register('companyFilter')}
                                                        >
                                                            <MenuItem value="">All</MenuItem>
                                                            {uniqueCompanies.map((company) => (
                                                                <MenuItem key={company} value={company}>{company}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </form>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Username</TableCell>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Company</TableCell>
                                                        <TableCell>Email</TableCell>
                                                        <TableCell onClick={() => {
                                                        setSortField('usedCredit');
                                                        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                    }} style={{ cursor: 'pointer' }}>
                                                        Used Credit {sortField === 'usedCredit' && (sortDirection === 'asc' ? '▲' : '▼')}
                                                    </TableCell>
                                                        <TableCell>Registered Date</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filteredData.map((user: GetUserListModel) => (
                                                        <TableRow key={user.userName}>
                                                            <TableCell>{user.userName}</TableCell>
                                                            <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                                                            <TableCell>{user.companyName}</TableCell>
                                                            <TableCell>{user.email}</TableCell>
                                                            <TableCell>{user.usedCredit}</TableCell>
                                                            <TableCell>{user.registeredDateTime}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </FormProvider>
                // )
                //     : (
                //         <></>
                //     )
            }
        </>
    );
}