import { UploadSvfBoxRequestModel, UploadSvfBoxResponseModel } from "../../models/box/upload.svf.box.model";
import { BasePutService } from "../base/base.put.service";




export class UploadSvfBoxService extends BasePutService<UploadSvfBoxRequestModel,UploadSvfBoxResponseModel>{
    constructor(controllerName:string="box", endpoint:string="uploadSvfToBox"){
        super(controllerName, endpoint)
    }


    async UploadSvfWithFormDataAsync(request:UploadSvfBoxRequestModel){
        const formData=new FormData()

       
        formData.append("bucketName",request.bucketName)
        formData.append("urn",request.urn)
        formData.append("userId",request.userId)
        formData.append("mainFileName",request.mainFileName)
 
        return await this.PutWithFormDataAsync(formData)
    }
}